import { createSlice } from "@reduxjs/toolkit";
import type { AppState } from "../types/index.type";

const initialState: AppState = {
  isCreateClientDrawerVisible: false,
  isCustomiseSectionVisible: false,
  selectedClient: null,
  uploadInProgress: false,
  isEditNote: false,
  isCreateModalVisible: false,
  isAITemplateModalVisible: false,
  isSubmissionInProgress: false,
  selectedNoteForEditing: null,
  isNotesViewVisible: false,
  uploadType: 0,
  showRecordingView: false,
  loadingNotes: true,
  currentPage: 1,
  isDemoPath: false,
  isMobilePath: false,
  openShare: false,
  showDemoSignupModal: false,
  addClientView: false,
  audioDevices: [],
  demoState: {
    isClientModalOpen: false,
    isNoteModalOpen: false,
    clientForm: null,
    recordingDetails: null,
  },
  templateOpenedFromNoteModal: false,
};

const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setCreateClientState: (state, action) => {
      state.isCreateClientDrawerVisible = action.payload;
    },
    setCustomiseSectionState: (state, action) => {
      state.isCustomiseSectionVisible = action.payload;
    },
    setSelectedClient: (state, action) => {
      state.selectedClient = action.payload;
    },
    setUploadProgressState: (state, action) => {
      state.uploadInProgress = action.payload;
    },
    setEditingState: (state, action) => {
      state.isEditNote = action.payload;
    },
    setCreateModalState: (state, action) => {
      state.isCreateModalVisible = action.payload;
    },
    setAITemplateModalState: (state, action) => {
      state.isAITemplateModalVisible = action.payload;
    },
    setSubmissionProgressState: (state, action) => {
      state.isSubmissionInProgress = action.payload;
    },
    setSelectedNoteForEditing: (state, action) => {
      state.selectedNoteForEditing = action.payload;
    },
    setNotesViewVisible: (state, action) => {
      state.isNotesViewVisible = action.payload;
    },
    setUploadType: (state, action) => {
      state.uploadType = action.payload;
    },
    setShowRecordingView: (state, action) => {
      state.showRecordingView = action.payload;
    },
    setLoadingNotesState: (state, action) => {
      state.loadingNotes = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setIsDemoPath: (state, action) => {
      state.isDemoPath = action.payload;
    },
    setShowDemoSignupModal: (state, action) => {
      state.showDemoSignupModal = action.payload;
    },
    clearAppState: (_state) => {
      _state = initialState;
    },
    setDemoState: (state, action) => {
      state.demoState = action.payload;
    },
    setAddClientView: (state, action) => {
      state.addClientView = action.payload;
    },
    setOpenShare: (state, action) => {
      state.openShare = action.payload;
    },
    setMobilePath: (state, action) => {
      state.isMobilePath = action.payload;
    },
    setAudioDevices: (state, action) => {
      state.audioDevices = action.payload;
    },
    setTemplateOpenedFromNoteModal: (state, action) => {
      state.templateOpenedFromNoteModal = action.payload;
    },
  },
});

export const {
  setCreateClientState,
  setCustomiseSectionState,
  setSelectedClient,
  setUploadProgressState,
  setEditingState,
  setCreateModalState,
  setAITemplateModalState,
  setSubmissionProgressState,
  setSelectedNoteForEditing,
  setNotesViewVisible,
  setUploadType,
  setOpenShare,
  setShowRecordingView,
  setLoadingNotesState,
  setCurrentPage,
  setIsDemoPath,
  setDemoState,
  setAddClientView,
  setShowDemoSignupModal,
  setMobilePath,
  setAudioDevices,
  setTemplateOpenedFromNoteModal,
} = appStateSlice.actions;
export default appStateSlice.reducer;
