export default function Baa() {
  return (
    <div
      style={{
        textAlign: "left",
        width: "90vw",
        margin: "auto",
        color: "#000",
      }}
    >
      Privacy
    </div>
  );
}
