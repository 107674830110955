import { InboxOutlined } from "@ant-design/icons";
import { Col, UploadProps } from "antd";
import Dragger from "antd/es/upload/Dragger";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { setRecordingDetail } from "../../slices/recordingsSlice";
import styles from "./createnotemodal.module.scss";

const FileUploader = ({
  audioFileRef,
}: {
  audioFileRef: React.MutableRefObject<any>;
}) => {
  const dispatch = useAppDispatch();
  const componentName = "createnotemodal";

  const { recordingDetail } = useAppSelector((state) => state.recordings);

  const props: UploadProps = {
    name: "file",
    accept: "audio/*",
    multiple: false,
    onChange(info) {
      //@ts-ignore
      audioFileRef.current = info.file;
      return false;
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    onRemove: (file) => {
      if (!recordingDetail || !recordingDetail.fileList) {
        return;
      }
      const index = recordingDetail.fileList.indexOf(file);
      const newFileList = recordingDetail.fileList.slice();
      newFileList.splice(index, 1);
      dispatch(setRecordingDetail({ ...recordingDetail, fileList: newFileList }));
    },
    beforeUpload(file) {
      dispatch(setRecordingDetail({ ...recordingDetail, fileList: [file] }));
      return false;
    },
    fileList: recordingDetail.fileList || [],
  };
  return (
    <Col span={24} className={`${styles[`${componentName}__modal-row`]}`} style={{ marginTop: "15px" }}>
      <Dragger height={75} {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
      </Dragger>
    </Col>
  );
};

export default FileUploader;
