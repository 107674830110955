import TextArea from "antd/es/input/TextArea";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { setNotesDetails } from "../../slices/notesSlice";
import styles from "./notes.module.scss";

const NotesTextArea = ({
  value,
  field,
  bigger = false,
  style,
}: {
  value: string;
  field: string;
  bigger?: boolean;
  style?: React.CSSProperties;
}) => {
  const dispatch = useAppDispatch();
  const { notesDetails } = useAppSelector((state) => state.notes);
  const componentName = "notes";
  if (!notesDetails) {
    return null;
  }
  return (
    <div className={`${styles[`${componentName}__main-section-description`]}`} style={style}>
      <TextArea
        className={`${styles[`${componentName}__main-section-textarea`]}`}
        value={value}
        autoSize={bigger ? { minRows: 3, maxRows: 25 } : { minRows: 3, maxRows: 6 }}
        onChange={(event) => {
          dispatch(
            setNotesDetails({
              ...notesDetails,
              [field]: event.target.value,
            }),
          );
        }}
      />
    </div>
  );
};

export default NotesTextArea;
