import { Card, Flex } from "antd";
import { useDispatch } from "react-redux";
import CustomiseNoteCTA from "../../components/CustomiseNoteCTA/CustomiseNoteCTA.component";
import { useAppSelector } from "../../hooks/redux-hooks";
import { setAITemplateModalState } from "../../slices/appStateSlice";
import type { NoteSection } from "../../utils/analytics";
import { copySectionContent } from "../../utils/datamanipulation.utils";
import NoteCopyButton from "./NoteCopyButton.component";
import NoteSectionTitle from "./NoteSectionTitle.component";
import NotesTextArea from "./NotesTextArea.component";
import SaveButton from "./SaveButton.component";
import styles from "./notes.module.scss";

const NoteSectionEdit = ({
  value,
  title,
  field,
  analyticsField,
  bigger = false,
  handleSaveClick,
  hasCustomize = true,
}: {
  value: string;
  title: string;
  field: string;
  bigger?: boolean;
  analyticsField: NoteSection;
  handleSaveClick: () => void;
  hasCustomize?: boolean;
}) => {
  const { notesDetails } = useAppSelector((state) => state.notes);
  const dispatch = useDispatch();
  const componentName = "notes";
  const onCopy = () => {
    if (!notesDetails) return;
    copySectionContent(notesDetails, value, analyticsField);
  };

  const openAITemplateModal = () => {
    dispatch(setAITemplateModalState(true));
  };

  return (
    <Flex className={`${styles[`${componentName}__main-container`]}`}>
      <Card className={`${styles[`${componentName}__main-section`]}`} hoverable>
        <Flex justify="space-between" align="center">
          <NoteSectionTitle title={title} />
          <NoteCopyButton onClick={onCopy} />
        </Flex>
        {hasCustomize && (
          <>
            <Flex wrap="wrap" gap={10} style={{ marginTop: "10px" }}>
              <CustomiseNoteCTA text="Remove section on " buttonText="Remove section on..." />
              <CustomiseNoteCTA text="Increase detail within " buttonText="Increase detail within..." />
              <CustomiseNoteCTA text="Use bullet points across " buttonText="Use bullet points across..." />
              <CustomiseNoteCTA text="" buttonText="Add my own instruction..." />
            </Flex>
            <div
              style={{ marginTop: "10px" }}
              className={styles[`${componentName}__container-right-container-bottomcta-text`]}
              onClick={openAITemplateModal}
            >
              <span style={{ textDecoration: "underline", color: "#6366f1" }}>
                I'd prefer to create my own note template
              </span>
            </div>
          </>
        )}
        <NotesTextArea
          style={{ marginTop: "18px", paddingTop: "10px", borderTop: "1px solid #e0e0e0" }}
          value={value}
          field={field}
          bigger={bigger}
        />
        <SaveButton onClick={handleSaveClick} />
      </Card>
    </Flex>
  );
};

export default NoteSectionEdit;
