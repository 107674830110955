import { Button } from "antd";
import { useAppSelector } from "../../hooks/redux-hooks";
import styles from "./notes.module.scss";

const SaveButton = ({ onClick }: { onClick: () => void }) => {
  const componentName = "notes";
  const { isDemoPath } = useAppSelector((state) => state.appState);
  return (
    <div className={`${styles[`${componentName}__main-section-action`]}`}>
      {!isDemoPath && (
        <Button type="primary" onClick={onClick} className={`${styles[`${componentName}__main-section-save-button`]}`}>
          Save Changes
        </Button>
      )}
    </div>
  );
};

export default SaveButton;
