import { DownOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Collapse,
  Dropdown,
  Flex,
  Input,
  type MenuProps,
  Slider,
  type SliderSingleProps,
  Space,
  message,
} from "antd";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { NoteType, getNotesType } from "../../domain/notes.domain";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { useIsMobileView } from "../../hooks/ui-hook";
import { getCustomizationList, handleWriteNoteSubmission } from "../../service/notes.service";
import { setCustomiseSectionState, setNotesViewVisible, setShowDemoSignupModal } from "../../slices/appStateSlice";
import { setCustomizationList, setNoteTypeInstructionNote } from "../../slices/clientSlice";
import { resetNotesDetails, setNotesDetails } from "../../slices/notesSlice";
import { ModalityMode } from "../../types/index.type";
import styles from "./customise-note.module.scss";

const formatter: NonNullable<SliderSingleProps["tooltip"]>["formatter"] = (value) => {
  if (value === 0) {
    return "Low";
  }
  if (value === 1) {
    return "Default";
  }
  return "High";
};

const formatterFormatting: NonNullable<SliderSingleProps["tooltip"]>["formatter"] = (value) => {
  if (value === 0) {
    return "Bullet";
  }
  if (value === 1) {
    return "Paragraph";
  }
  return "Numbered";
};

type TProps = {
  noPadding?: boolean;
};

const CustomiseNote: React.FC<TProps> = ({ noPadding }: TProps) => {
  const submitButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const componentName = "customise-note";
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [settings, setSettings] = React.useState({
    note_section: "all",
    is_system: false,
    detail_level: 1,
    formatting: 1,
  });
  const { notesDetails } = useAppSelector((state) => state.notes);
  const { clients } = useAppSelector((state) => state.client);
  const { isDemoPath, isMobilePath, isCustomiseSectionVisible } = useAppSelector((state) => state.appState);
  const { customizationList, note_type_instructions } = useAppSelector((state) => state.client);
  const [instructions, setInstructions] = React.useState("");
  const textareaRef = React.useRef<any>(null);

  // Clear note_type_instructions when drawer closes
  React.useEffect(() => {
    if (!isCustomiseSectionVisible) {
      dispatch(setNoteTypeInstructionNote({}));
      setInstructions("");
    }
  }, [isCustomiseSectionVisible, dispatch]);

  // Add effect to initialize instructions from note_type_instructions
  React.useEffect(() => {
    if (notesDetails?.note_type !== undefined && note_type_instructions[notesDetails.note_type]) {
      const instruction = note_type_instructions[notesDetails.note_type];
      if (typeof instruction === "object" && "note" in instruction) {
        setInstructions(instruction.note);
        // Focus the textarea after setting instructions
        setTimeout(() => {
          textareaRef.current?.focus();
        }, 0);
      }
    }
  }, [notesDetails?.note_type, note_type_instructions]);

  const SOAP_ITEMS = [
    {
      key: "1",
      value: "all",
      label: (
        <div
          onClick={() => {
            setSettings((prev) => ({ ...prev, note_section: "all" }));
          }}
        >
          Entire Note
        </div>
      ),
    },
    {
      key: "2",
      value: "subjective",
      label: (
        <div
          onClick={() => {
            setSettings((prev) => ({ ...prev, note_section: "subjective" }));
          }}
        >
          Subjective Only
        </div>
      ),
    },
    {
      key: "3",
      value: "objective",
      label: (
        <div
          onClick={() => {
            setSettings((prev) => ({ ...prev, note_section: "objective" }));
          }}
        >
          Objective Only
        </div>
      ),
    },
    {
      key: "4",
      value: "assessment",
      label: (
        <div
          onClick={() => {
            setSettings((prev) => ({ ...prev, note_section: "assessment" }));
          }}
        >
          Assessment Only
        </div>
      ),
    },
    {
      key: "5",
      value: "plan",
      label: (
        <div
          onClick={() => {
            setSettings((prev) => ({ ...prev, note_section: "plan" }));
          }}
        >
          Plan Only
        </div>
      ),
    },
  ];

  const DAP_ITEM = [
    {
      key: "1",
      value: "all",
      label: (
        <div
          onClick={() => {
            setSettings((prev) => ({ ...prev, note_section: "all" }));
          }}
        >
          Entire Note
        </div>
      ),
    },
    {
      key: "2",
      value: "data",
      label: (
        <div
          onClick={() => {
            setSettings((prev) => ({ ...prev, note_section: "data" }));
          }}
        >
          Data Only
        </div>
      ),
    },
    {
      key: "3",
      value: "assessment",
      label: (
        <div
          onClick={() => {
            setSettings((prev) => ({ ...prev, note_section: "assessment" }));
          }}
        >
          Assessment Only
        </div>
      ),
    },
    {
      key: "4",
      value: "plan",
      label: (
        <div
          onClick={() => {
            setSettings((prev) => ({ ...prev, note_section: "plan" }));
          }}
        >
          Plan Only
        </div>
      ),
    },
  ];

  const customizationInstructions = [
    "Write short instructions to customize parts of the note",
    "Do not copy/paste an entirely new template",
    "Try using direct instructions instead of if/else statements",
  ];

  const items: MenuProps["items"] = notesDetails?.note_type === NoteType.SOAP ? SOAP_ITEMS : DAP_ITEM;

  const marks: SliderSingleProps["marks"] = {
    0: "Low",
    1: "Default",
    2: "High",
  };
  const marksFormatting: SliderSingleProps["marks"] = {
    0: "Bullet",
    1: "Paragraph",
    2: "Numbered",
  };

  const customize = () => {
    if (isDemoPath) {
      dispatch(setShowDemoSignupModal(true));
      dispatch(setCustomiseSectionState(false));
      return;
    }
    if (notesDetails) {
      const clientUuid = clients.find((c) => c.id === notesDetails.client)?.uuid;
      handleWriteNoteSubmission({
        audio_id: notesDetails?.id,
        title: notesDetails.title,
        note_type: notesDetails.note_type,
        category_type: notesDetails.category_type,
        modality_mode: ModalityMode[notesDetails.modality_type],
        gender_type: notesDetails.gender_type,
        client_id: notesDetails.client,
        location_type: notesDetails.location_type,
        duration_type: notesDetails.duration_type,
        duration_addon_type: notesDetails.duration_addon_type,
        customization: {
          customization_type:
            notesDetails?.note_type === NoteType.SOAP ? 0 : notesDetails?.note_type === NoteType.DAP ? 1 : 2,
          note_type_instructions: {
            ...note_type_instructions,
            [notesDetails?.note_type]: {
              note: instructions,
              is_system: settings.is_system,
            },
          },
          is_system: settings.is_system,
          note_section: settings.note_section,
          detail_level: settings.detail_level,
          formatting: settings.formatting,
        },
      })
        .then(() => {
          const rootPath = isMobilePath ? "/notesforMobile" : "/home";
          if (clientUuid) {
            navigate(`${rootPath}/clients/${clientUuid}`);
          } else {
            navigate(rootPath);
          }
          dispatch(setCustomiseSectionState(false));
          dispatch(setNotesViewVisible(false));
          dispatch(setNotesDetails(null));
          dispatch(setCustomiseSectionState(false));
          dispatch(resetNotesDetails());
          getCustomizationList()
            .then((response) => {
              dispatch(setCustomizationList(response.data));
            })
            .catch((error) => {
              console.error("Error fetching customization list:", error);
            });
          message.success("Regenerating note with customizations");
          if (settings.is_system) {
            message.success("Customization saved for all future notes");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const getSelection = () => {
    const options = notesDetails?.note_type === NoteType.SOAP ? SOAP_ITEMS : DAP_ITEM;
    return <>{options.find((item) => item.value === settings.note_section)?.label || ""}</>;
  };

  const restoreExistingSettings = useCallback(() => {
    if (customizationList?.length) {
      const type = notesDetails?.note_type === NoteType.SOAP ? 0 : notesDetails?.note_type === NoteType.DAP ? 1 : 2;
      const existingSettings = customizationList.find((item) => item.customization_type === type);
      if (existingSettings) {
        setSettings({
          note_section: existingSettings.customization.note_section,
          is_system: existingSettings.customization.is_system,
          detail_level: existingSettings.customization.detail_level,
          formatting: existingSettings.customization.formatting,
        });
        if (existingSettings.customization.note_type_instructions && notesDetails && notesDetails.note_type) {
          dispatch(setNoteTypeInstructionNote(existingSettings.customization.note_type_instructions));
          if (typeof existingSettings.customization.note_type_instructions[notesDetails.note_type] === "object") {
            const { note, is_system } = existingSettings.customization.note_type_instructions[notesDetails.note_type];
            if (is_system) {
              setInstructions(note);
              setSettings((prev) => ({ ...prev, is_system }));
            }
          }
        }
      }
    }
  }, [customizationList, notesDetails, dispatch]);

  React.useEffect(() => {
    restoreExistingSettings();
  }, [restoreExistingSettings]);

  const resetToDefault = () => {
    setSettings({
      note_section: "all",
      is_system: false,
      detail_level: 1,
      formatting: 1,
    });
    setInstructions("");
    if (submitButtonRef && submitButtonRef.current) {
      submitButtonRef.current.innerText = "Save and Regenerate Note";
    }
  };

  if (!notesDetails) {
    return null;
  }

  return (
    <Flex
      vertical
      gap={20}
      style={{
        padding: noPadding ? "0px" : "20px",
        height: notesDetails.note_type === 0 || notesDetails.note_type === 2 ? "auto" : "100%",
      }}
    >
      {(notesDetails.note_type === 0 || notesDetails.note_type === 2) && (
        <>
          <Flex>
            <Dropdown menu={{ items }}>
              <Button onClick={(e) => e.preventDefault()}>
                <Space>
                  Apply to <span>{getSelection()}</span>
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Flex>
          <Flex vertical>
            <Flex vertical>
              <Collapse
                className={styles[`${componentName}__collapse`]}
                items={[
                  {
                    key: "1",
                    label: "Detail level",
                    extra: <b>{formatter(settings.detail_level)}</b>,
                    children: (
                      <Flex
                        vertical
                        style={{
                          padding: "0px 20px",
                        }}
                      >
                        <Slider
                          marks={marks}
                          min={0}
                          max={2}
                          value={settings.detail_level}
                          onChange={(value) =>
                            setSettings((prev) => ({
                              ...prev,
                              detail_level: value,
                            }))
                          }
                          tooltip={{ formatter }}
                          styles={{
                            track: {
                              backgroundColor: "#6366F1",
                            },
                          }}
                        />
                        {/* <Divider />
                      <Flex vertical>
                        <Flex gap={10}>
                          <QuestionCircleOutlined />
                          What's this
                        </Flex>
                        <Flex
                          style={{
                            marginLeft: "25px",
                          }}
                        >
                          Lorum Ipsum
                        </Flex>
                      </Flex> */}
                      </Flex>
                    ),
                  },
                ]}
              />
            </Flex>
          </Flex>
          <Flex>
            <Collapse
              className={styles[`${componentName}__collapse`]}
              items={[
                {
                  key: "1",
                  extra: <b>{formatterFormatting(settings.formatting)}</b>,
                  label: "Formatting style",
                  children: (
                    <Flex
                      vertical
                      style={{
                        padding: "0px 20px",
                      }}
                    >
                      <Slider
                        marks={marksFormatting}
                        value={settings.formatting}
                        onChange={(value) =>
                          setSettings((prev) => ({
                            ...prev,
                            formatting: value,
                          }))
                        }
                        min={0}
                        max={2}
                        tooltip={{ formatter: formatterFormatting }}
                        styles={{
                          track: {
                            backgroundColor: "#6366F1",
                          },
                        }}
                      />
                      {/* <Divider />
                    <Flex vertical>
                      <Flex gap={10}>
                        <QuestionCircleOutlined />
                        What's this
                      </Flex>
                      <Flex
                        style={{
                          marginLeft: "25px",
                        }}
                      >
                        Lorum Ipsum
                      </Flex>
                    </Flex> */}
                    </Flex>
                  ),
                },
              ]}
            />
          </Flex>
        </>
      )}
      {!(notesDetails.note_type === 0 || notesDetails.note_type === 2) && (
        <Flex
          vertical
          gap={5}
          style={{
            height: "100%",
          }}
        >
          <Flex vertical>
            <Flex
              style={{
                fontWeight: "600",
              }}
            >
              Add customization instructions:
            </Flex>
            <Flex>
              <ul
                style={{
                  listStylePosition: "inside",
                  paddingLeft: "0px",
                  marginTop: "6px",
                  marginBottom: "0px",
                }}
              >
                {customizationInstructions.map((item) => (
                  <li
                    key={item}
                    style={{
                      padding: "2px",
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </Flex>
            <Flex
              style={{
                fontSize: "14px",
                margin: "5px 0",
                textDecoration: "underline",
                cursor: "pointer",
                color: "var(--primary)",
              }}
              onClick={() => {
                window.open("https://getnudgeai.com/customize-note-faq", "_blank");
              }}
            >
              Learn more from examples
            </Flex>
          </Flex>
          <Input.TextArea
            ref={textareaRef}
            value={instructions}
            onChange={(e) => {
              setInstructions(e.target.value);
              if (submitButtonRef?.current) {
                submitButtonRef.current.innerText = "Save and Regenerate Note";
              }
            }}
            style={{
              height: "100%",
            }}
            maxLength={1000}
            placeholder={`- Remove section on...
- Add a summary before section...
- Increase detail within...
- Use bullets points across...`}
          />
          <Flex justify="flex-end">
            <span onClick={resetToDefault} className={styles[`${componentName}__collapse-reset`]}>
              Reset to default
            </span>
          </Flex>
        </Flex>
      )}
      <Flex
        style={{
          backgroundColor: "var(--grey-bg)",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <Checkbox
          onChange={(e) => {
            setSettings((prev) => ({ ...prev, is_system: e.target.checked }));
            if (submitButtonRef?.current) {
              submitButtonRef.current.innerText = "Save and Regenerate Note";
            }
          }}
          className={styles[`${componentName}__collapse-checkbox`]}
          checked={settings.is_system}
        >
          Save configuration for future {getNotesType(notesDetails.note_type)} notes.
        </Checkbox>
      </Flex>
      <Flex>
        <Button
          className={styles[`${componentName}__collapse-save`]}
          type="primary"
          size="middle"
          onClick={customize}
          ref={submitButtonRef}
        >
          Save & Regenerate Note
        </Button>
      </Flex>
    </Flex>
  );
};

export default CustomiseNote;
