import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Collapse, Flex, Steps } from "antd";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../hooks/redux-hooks";
import HowToTell from "./HowToTell.component";
import styles from "./tutorial.module.scss";

const VideoPlayer = ({ src }: { src: string; alt: string }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [_isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
        if (entry.isIntersecting && videoRef.current) {
          setTimeout(() => {
            if (videoRef.current) {
              videoRef.current.play();
            }
          }, 2000);
        } else if (videoRef.current) {
          videoRef.current.pause();
        }
      },
      { threshold: 0.5 },
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return <video ref={videoRef} src={src} loop muted playsInline className={styles[`tutorial__video`]} />;
};

const Tutorial = () => {
  const component = "tutorial";
  const { isMobilePath } = useAppSelector((state) => state.appState);
  const [expandedSteps, setExpandedSteps] = useState<{
    [key: number]: boolean;
  }>({ 0: true });

  const toggleStep = (stepIndex: number) => {
    setExpandedSteps((prev) => ({
      ...prev,
      [stepIndex]: !prev[stepIndex],
    }));
  };

  const Description = ({
    description,
    img,
    stepIndex,
    videoUrl,
  }: {
    description: string;
    img?: string;
    stepIndex: number;
    videoUrl?: string;
  }) => {
    return (
      <Flex vertical>
        <p className={styles[`${component}__step-description`]}>{description}</p>
        {img && <img src={img} alt="Capture Session" className={styles[`${component}__step-image`]} />}
        {videoUrl && (
          <>
            <Button type="link" onClick={() => toggleStep(stepIndex)} className={styles[`${component}__show-more`]}>
              <Flex align="center" gap={8}>
                {expandedSteps[stepIndex] ? "Show less" : "Show more"}
                {expandedSteps[stepIndex] ? <UpOutlined /> : <DownOutlined />}
              </Flex>
            </Button>
            {expandedSteps[stepIndex] && (
              <div className={styles[`${component}__video-container`]}>
                <VideoPlayer src={videoUrl} alt={`Step ${stepIndex + 1} demonstration`} />
              </div>
            )}
          </>
        )}
      </Flex>
    );
  };

  const Title = ({ title }: { title: string }) => {
    return <p className={styles[`${component}__step-title`]}>{title}</p>;
  };

  const getSecondStep = () => {
    return {
      title: <Title title="Fill in note information" />,
      description: (
        <Description
          description="Fill in the details of the note when the drawer opens."
          stepIndex={1}
          videoUrl="/assets/tutorial-step-2.mp4"
        />
      ),
    };
  };

  return (
    <Flex vertical className={styles[`${component}__container`]}>
      <Collapse
        defaultActiveKey={["1"]}
        className={styles[`${component}__container-tutorial`]}
        items={[
          {
            key: "1",
            label: "How to get started in 3 steps",
            children: (
              <Steps
                current={-1}
                direction="vertical"
                className={styles[`${component}__step`]}
                items={[
                  {
                    title: <Title title="CREATE NOTE" />,
                    description: (
                      <Description
                        description="Click the Start A Session button and choose how you want to create your note: Live Session, Dictate, Upload, or Describe."
                        stepIndex={0}
                        videoUrl="/assets/tutorial-step-1.mp4"
                      />
                    ),
                  },
                  getSecondStep(),
                  {
                    title: <Title title="Generate and Review Note" />,
                    description: (
                      <Description
                        description="Wait until the note generates, and then proceed to review the note."
                        stepIndex={2}
                        videoUrl="/assets/tutorial-step-3.mp4"
                      />
                    ),
                  },
                ]}
              />
            ),
          },
        ]}
      />
      {!isMobilePath && <HowToTell />}
    </Flex>
  );
};

export default Tutorial;
