import { Button, Card, Flex, Modal } from "antd";
import React from "react";
import { DEFAULT_PRICE, MONTHLY_PRICE, YEARLY_PRICE } from "../../constants/data";
import { isFromSOL } from "../../domain/groups.domain";
import { isLastPlusOneNote } from "../../domain/user.domain";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { handleCheckoutSevice } from "../../service/payment.service";
import { setShowPaymentModal } from "../../slices/userSlice";
import { EventType, extractGAClientId, extractGASessionId, trackEvent } from "../../utils/analytics";
import styles from "./purchase.module.scss";

export default function PurchaseModal() {
  const componentStyles = "purchase";
  const dispatch = useAppDispatch();
  const open = useAppSelector((state) => state.user.showPaymentModal);
  const { userInfo } = useAppSelector((state) => state.auth);

  const [buttonDisabled, setButtonDisabled] = React.useState(false);

  const contactUs = () => {
    trackEvent(EventType.CONTACT_US);
    if (isFromSOL(userInfo)) {
      window.open("https://tally.so/r/3jLbZa", "_blank");
    } else {
      window.open("https://tally.so/r/wg5QxK", "_blank");
    }
  };

  function calculateFinalPrice(monthly: boolean) {
    let basePrice = monthly === true ? MONTHLY_PRICE : YEARLY_PRICE * 12;
    return basePrice * 1;
  }

  function handleCheckoutClick(monthly: boolean) {
    setButtonDisabled(true);
    const finalPrice = calculateFinalPrice(monthly) * 1;
    const tierType = "pro";

    trackEvent(EventType.CHECKOUT_SUBSCRIPTION, {
      numSeats: 1,
      billingPeriod: monthly ? "monthly" : "yearly",
      finalPrice,
      tierType,
    });
    handleCheckoutSevice({
      final_price: finalPrice,
      billing_period: monthly ? "monthly" : "yearly",
      tier_type: tierType,
      seats: 1,
      properties: {
        ga_client_id: extractGAClientId() || "",
        ga_session_id: extractGASessionId() || "",
      },
    })
      .then((response) => {
        const paymentLink = response.data.payment_link;
        window.location.href = paymentLink;
      })
      .catch(() => {
        setButtonDisabled(false);
      })
      .finally(() => {
        setButtonDisabled(false);
      });
  }
  return (
    <Modal
      open={open}
      onCancel={() => {
        dispatch(setShowPaymentModal(false));
      }}
      footer={null}
      title="Select a Plan"
      zIndex={1100}
    >
      {isLastPlusOneNote(userInfo) && (
        <div style={{ marginBottom: 10 }}>
          You've reached our max free note limit. Don't worry - your notes are safe! Upgrade now to unlock unlimited
          notes and get immediate access to all your notes.
        </div>
      )}
      <Card className={`${styles[`${componentStyles}__main-right-card`]}`}>
        <Flex vertical justify="space-between">
          <Flex vertical>
            <Flex className={`${styles[`${componentStyles}__main-right-card-title`]}`}>Yearly Subscription</Flex>
            <Flex
              className={`${styles[`${componentStyles}__main-right-card-price`]}`}
              align="center"
              justify="space-between"
            >
              <span>
                <>
                  ${YEARLY_PRICE}&nbsp;
                  <span
                    style={{
                      textDecoration: "line-through",
                      fontSize: 14,
                    }}
                  >
                    ${DEFAULT_PRICE}
                  </span>
                  &nbsp;
                  <span
                    style={{
                      color: "green",
                      fontSize: 14,
                    }}
                  >
                    (~
                    {Math.round(((DEFAULT_PRICE - YEARLY_PRICE) / DEFAULT_PRICE) * 100).toFixed(0)}% discount)
                  </span>
                </>
              </span>
              <Button
                type="primary"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  handleCheckoutClick(false);
                }}
                disabled={buttonDisabled}
              >
                Checkout
              </Button>
            </Flex>
            <Flex className={`${styles[`${componentStyles}__main-right-card-sub`]}`}>
              per user/month (billed annually)
            </Flex>
            <div className={`${styles[`${componentStyles}__main-right-card-divider`]}`}></div>
            <Flex className={`${styles[`${componentStyles}__main-right-card-title`]}`}>Monthly Subscription</Flex>
            <Flex
              className={`${styles[`${componentStyles}__main-right-card-price`]}`}
              align="center"
              justify="space-between"
            >
              <span>
                <>
                  ${MONTHLY_PRICE}&nbsp;
                  <span
                    style={{
                      textDecoration: "line-through",
                      fontSize: 14,
                    }}
                  >
                    ${DEFAULT_PRICE}
                  </span>
                  &nbsp;
                  <span
                    style={{
                      color: "green",
                      fontSize: 14,
                    }}
                  >
                    (~
                    {Math.round(((DEFAULT_PRICE - MONTHLY_PRICE) / DEFAULT_PRICE) * 100).toFixed(0)}% discount)
                  </span>
                </>
              </span>
              <Button
                type="primary"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  handleCheckoutClick(true);
                }}
                disabled={buttonDisabled}
              >
                Checkout
              </Button>
            </Flex>
            <Flex className={`${styles[`${componentStyles}__main-right-card-sub`]}`}>per user/month</Flex>
          </Flex>
          <div className={`${styles[`${componentStyles}__main-right-card-divider`]}`}></div>
          <Flex className={`${styles[`${componentStyles}__main-right-card-title`]}`}>
            Group Plan: &nbsp;
            <span
              onClick={contactUs}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Contact us
            </span>
          </Flex>
          <span
            style={{
              marginTop: 20,
              fontSize: 12,
            }}
          >
            <span>Student or a trainee? We've got your back. </span>
            <span
              onClick={contactUs}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Reach out for a discount.
            </span>
          </span>
        </Flex>
      </Card>
    </Modal>
  );
}
