import { Badge } from "antd";
import { GENDER_TYPE } from "../../domain/notes.domain";

type Props = {
  gender_type?: number | null | undefined;
};

export default function GenderType({ gender_type }: Props) {
  if (gender_type === GENDER_TYPE.NEUTRAL) return <Badge count={"Neutral"} showZero className="badge-soap" />;
  if (gender_type === GENDER_TYPE.MALE) return <Badge count={"He/Him"} showZero className="badge-soap" />;
  else return <Badge count={"She/Her"} showZero className="badge-soap" />;
}
