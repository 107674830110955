import { useEffect, useState } from "react";

export const useIsMobileView = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobileView;
};

export const useScrollToTop = () => {
  const isMobileView = useIsMobileView();
  const scrollToTop = () => {
    const container = isMobileView
      ? document.getElementsByClassName("ant-tabs-content-holder")?.[0]
      : document.getElementById("notes-container");

    container?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return scrollToTop;
};
