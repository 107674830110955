import { toInteger } from "lodash";

const getHashFromString = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

const getRandomAvatarColor = (name: string) => {
  const colors = ["#c3f1cc", "#dcdcdd", "#c7ebfc", "#febcb7", "#bfe9fb", "#ffe7a0", "#bff0c8", "#ffdcab", "#fec7d3"];

  const index = getHashFromString(name);
  return colors[Math.abs(index) % colors.length];
};

const formatSeconds = (totalSeconds: number): string => {
  const minutes = Math.floor(totalSeconds / 60);
  const remainingSeconds = toInteger(totalSeconds % 60);

  return `${minutes}m ${remainingSeconds}s`;
};

export { formatSeconds, getHashFromString, getRandomAvatarColor };
