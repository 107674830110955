import { CloseOutlined } from "@ant-design/icons";
import * as Sentry from "@sentry/react";
import { Button, Card, Col, Drawer, Flex, Input, type InputRef, Row, Switch, Typography, message } from "antd";
import TextArea, { type TextAreaRef } from "antd/es/input/TextArea";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { isFromGWUL, isFromLLT, isFromSOL } from "../../domain/groups.domain";
import { NoteType } from "../../domain/notes.domain";
import { isLastPlusOneNote } from "../../domain/user.domain";
import { useRootPath } from "../../hooks/app.hook";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { useIsMobileView } from "../../hooks/ui-hook";
import { createClient, getAllClients, validateClientName } from "../../service/client.service";
import { handleUploadAudioSubmission, handleWriteNoteSubmission, updateNote } from "../../service/notes.service";
import {
  setAddClientView,
  setAudioDevices,
  setCreateModalState,
  setCustomiseSectionState,
  setDemoState,
  setNotesViewVisible,
  setSelectedClient,
  setShowDemoSignupModal,
  setShowRecordingView,
  setUploadType,
} from "../../slices/appStateSlice";
import { resetClientForm, setClients } from "../../slices/clientSlice";
import {
  setIsPaused,
  setIsRecording,
  setMediaRecorder,
  setRecordMimeType,
  setRecordingDetail,
  setRecordingDevice,
  setWithHeadphones,
} from "../../slices/recordingsSlice";
import { setShowPaymentModal } from "../../slices/userSlice";
import type { TClientForm } from "../../types/index.type";
import { EventType, getSystemMetadata, trackEvent } from "../../utils/analytics";
import { showPaymentModal, sortClientsByName } from "../../utils/datamanipulation.utils";
import { eventEmitter } from "../../utils/eventEmitter";
import { determineBestAudioFormat, stopMediaRecorderAndStopTracks } from "../../utils/recording.utils";
import { requestWakeLock } from "../../utils/wakeScreen";
import SelectableBadge from "../Badge/SelectableBadge.component";
import ClientForm from "../CreateClient/ClientForm.component";
import CPTCodeSelector from "./CPTCodeSelector.component";
import ClientSelector from "./ClientSelector.component";
import FileUploader from "./FileUploader.component";
import LocationSelector from "./LocationSelector.component";
import NoteTypeSelector from "./NoteTypeSelector.component";
import RowHeader from "./RowHeader.component";
import { DEFAULT_NOTE_TYPES, GWUL_NOTE_TYPES, LLT_NOTE_TYPES, SOL_NOTE_TYPES } from "./constants";
import styles from "./createnotemodal.module.scss";

type CreateNoteModalProps = {
  chunks: any[];
  captureAudioRef: React.RefObject<any>;
  captureRecordingIdRef: React.RefObject<any>;
  isCaptureEnabled: boolean;
  resetRecordingState: (withRefresh?: boolean) => void;
  handleRetry: () => void;
  initWebsocket: (audio_file_id: string) => Promise<unknown>;
  displayRecordingTimer: () => void;
  setRecordingTime: (time: number) => void;
  checkMicrophoneMuteStatus: (stream: MediaStream) => Promise<unknown>;
  onBufferCountChange: (count: number) => void;
  getCaptureDetails: () => object;
};
export const MODALITY_LIST = [
  {
    title: "Live Session",
    icon: (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 4.4974V9.4974M4 2.83073V11.1641M7 1.16406V12.8307M10 2.83073V11.1641M13 4.4974V9.4974"
          stroke="#6B7280"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    type: "audio",
  },
  {
    title: "Dictate",
    icon: (
      <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5 12.5C6.06087 12.5 7.07828 12.0786 7.82843 11.3284C8.57857 10.5783 9 9.56087 9 8.5V7.5M5 12.5C3.93913 12.5 2.92172 12.0786 2.17157 11.3284C1.42143 10.5783 1 9.56087 1 8.5V7.5M5 12.5V15M2.5 15H7.5M5 10.5C4.46957 10.5 3.96086 10.2893 3.58579 9.91421C3.21071 9.53914 3 9.03043 3 8.5V3C3 2.46957 3.21071 1.96086 3.58579 1.58579C3.96086 1.21071 4.46957 1 5 1C5.53043 1 6.03914 1.21071 6.41421 1.58579C6.78929 1.96086 7 2.46957 7 3V8.5C7 9.03043 6.78929 9.53914 6.41421 9.91421C6.03914 10.2893 5.53043 10.5 5 10.5Z"
          stroke="#6B7280"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    type: "dictate",
  },
  {
    title: "Upload",
    icon: (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 10V11.5C1 11.8978 1.15804 12.2794 1.43934 12.5607C1.72064 12.842 2.10218 13 2.5 13H11.5C11.8978 13 12.2794 12.842 12.5607 12.5607C12.842 12.2794 13 11.8978 13 11.5V10M4 4L7 1M7 1L10 4M7 1V10"
          stroke="#6B7280"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    type: "upload",
  },
  {
    title: "Describe",
    icon: (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.84031 2.4008L3.36697 8.19413C3.16031 8.41413 2.96031 8.84746 2.92031 9.14746L2.67364 11.3075C2.58697 12.0875 3.14697 12.6208 3.92031 12.4875L6.06698 12.1208C6.36698 12.0675 6.78697 11.8475 6.99364 11.6208L12.467 5.82746C13.4136 4.82746 13.8403 3.68746 12.367 2.29413C10.9003 0.914129 9.78698 1.4008 8.84031 2.4008Z"
          stroke="#6B7280"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.92667 3.36719C8.06643 4.26109 8.49907 5.08328 9.15668 5.70469C9.81428 6.32609 10.6596 6.71154 11.56 6.80052M2 14.6672H14"
          stroke="#6B7280"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    type: "write",
  },
];

const CreateNoteModal = React.forwardRef(
  (
    {
      chunks,
      captureAudioRef,
      captureRecordingIdRef,
      isCaptureEnabled,
      resetRecordingState,
      handleRetry,
      initWebsocket,
      displayRecordingTimer,
      setRecordingTime,
      checkMicrophoneMuteStatus,
      onBufferCountChange,
      getCaptureDetails,
    }: CreateNoteModalProps,
    ref: React.Ref<any>,
  ) => {
    const componentName = "createnotemodal";
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isMobile = useIsMobileView();
    const titleRef = React.useRef<InputRef>(null);
    const notesRef = React.useRef<TextAreaRef>(null);
    const audioFileRef = React.useRef<File | null>(null);
    const hasLoggedErrorRef = React.useRef<boolean>(false); // track state to avoid spamming this error
    const hasBackendReceivedBytesRef = React.useRef<boolean>(false);
    const userInfo = useAppSelector((state) => state.auth.userInfo);
    const {
      isEditNote,
      isDemoPath,
      isCreateModalVisible,
      selectedNoteForEditing,
      demoState,
      addClientView,
      isMobilePath,
    } = useAppSelector((state) => state.appState);
    const { clients, clientForm } = useAppSelector((state) => state.client);
    const { isRecording, isPaused, mediaRecorder, recordingDetail, recordingDevice, withHeadphones } = useAppSelector(
      (state) => state.recordings,
    );
    const { rootPath } = useRootPath();
    const [startingSession, setStartingSession] = React.useState(false);
    const [stream, setStream] = React.useState<MediaStreamTrack | null>(null);
    const [retryCount, setRetryCount] = React.useState<number>(0);

    // biome-ignore lint/correctness/useExhaustiveDependencies: handler only when the underlying device changes
    useEffect(() => {
      const handleDataAvailable = async (event: BlobEvent) => {
        if (event.data && event.data.size > 0) {
          try {
            const arrayBuffer = await event.data.arrayBuffer();
            chunks.push(arrayBuffer);
            if (captureAudioRef.current) {
              if (
                !!hasLoggedErrorRef.current &&
                (captureAudioRef.current.sendBuffer.length > 200 || !!captureAudioRef.current.connected)
              ) {
                if (!!hasBackendReceivedBytesRef.current) {
                  Swal.fire({
                    icon: "error",
                    title: "Connection Issue",
                    text: "We're having trouble establishing a connection. Please try refreshing the page. If the problem continues, our support team has been notified and is working on a solution.",
                  });
                }
                Sentry.captureException(new Error("Connection in bad state"), {
                  extra: {
                    connected: captureAudioRef.current.connected,
                    buffer_size: captureAudioRef.current.sendBuffer.length,
                  },
                });
                hasLoggedErrorRef.current = true;
              }
              let nextChunk = chunks.shift();
              while (nextChunk !== undefined) {
                if (nextChunk?.byteLength) {
                  onBufferCountChange(Number(nextChunk?.byteLength));
                }
                if (ref && "current" in ref) {
                  (ref as React.MutableRefObject<number>).current = Date.now();
                }

                captureAudioRef.current.emit(
                  "audiobuffer",
                  {
                    audio_id: captureRecordingIdRef.current,
                    buffer: nextChunk,
                  },
                  (response: boolean) => {
                    if (response) {
                      hasBackendReceivedBytesRef.current = true;
                    }
                  },
                );
                nextChunk = chunks.shift();
              }
            } else {
              if (!captureRecordingIdRef.current) {
                Swal.fire({
                  icon: "error",
                  title: "Connection Issue",
                  text: "We're having trouble establishing a connection. Please try refreshing the page. If the problem continues, our support team has been notified and is working on a solution.",
                });
              } else {
                if (!!hasLoggedErrorRef.current) {
                  Sentry.captureException(new Error("No active websocket connection"), {
                    extra: { audio_id: captureRecordingIdRef.current },
                  });
                  hasLoggedErrorRef.current = true;
                }
              }
            }
          } catch (error) {
            console.error("Error converting data to array buffer:", error);
          }
        }
      };
      mediaRecorder?.addEventListener("dataavailable", handleDataAvailable);
      return () => {
        mediaRecorder?.removeEventListener("dataavailable", handleDataAvailable);
      };
    }, [mediaRecorder]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: handler only when the underlying device changes
    useEffect(() => {
      if (isRecording || isPaused) {
        console.log("fire from useEffect");
        if (mediaRecorder) {
          stopMediaRecorderAndStopTracks(mediaRecorder);
        }
        handleNewDevice();
      }
    }, [recordingDevice?.deviceId, recordingDevice?.groupId]);

    useEffect(() => {
      async function handleInactive() {
        if (withHeadphones) {
          return;
        }

        try {
          const devices = await fetchAudioInputDevices();
          dispatch(setAudioDevices(devices));

          // Check if current device is still available
          const currentDevice = devices.find(
            (device) => device.deviceId === recordingDevice?.deviceId && device.groupId === recordingDevice?.groupId,
          );
          if (
            (isRecording || isPaused) &&
            ((!currentDevice && mediaRecorder?.state !== "inactive") ||
              (!mediaRecorder?.stream?.active && mediaRecorder?.state !== "inactive"))
          ) {
            console.log("firing from onchange handler");
            console.log(`setting device ${devices[0].groupId} ${devices[0].deviceId} ${devices[0].label}`);
            dispatch(setRecordingDevice(devices[0]));
          }
        } catch (error) {
          console.error("Error switching devices:", error);
        }
      }
      navigator.mediaDevices?.addEventListener("devicechange", handleInactive);
      return () => {
        navigator.mediaDevices?.removeEventListener("devicechange", handleInactive);
      };
    }, [
      isRecording,
      recordingDevice?.deviceId,
      recordingDevice?.groupId,
      mediaRecorder,
      withHeadphones,
      dispatch,
      isPaused,
    ]);

    useEffect(() => {
      async function handleTrackEnded() {
        console.log("Track ended init");
        const devices = await fetchAudioInputDevices();
        dispatch(setAudioDevices(devices));
        dispatch(setRecordingDevice(devices[0]));
      }

      stream?.addEventListener("ended", handleTrackEnded);
      return () => {
        stream?.removeEventListener("ended", handleTrackEnded);
      };
    }, [dispatch, stream]);

    const handleOpenPaymentModal = () => {
      dispatch(setShowPaymentModal(true));
    };

    const isNoteTypeDifferent = selectedNoteForEditing?.note_type !== recordingDetail.note_type;
    const isLocationTypeDifferent = selectedNoteForEditing?.location_type !== recordingDetail.location_type;
    const isDurationTypeDifferent = selectedNoteForEditing?.duration_type !== recordingDetail.duration_type;
    const isDurationAddonTypeDifferent =
      selectedNoteForEditing?.duration_addon_type !== recordingDetail.duration_addon_type;
    const needsRegeneration =
      isNoteTypeDifferent || isLocationTypeDifferent || isDurationTypeDifferent || isDurationAddonTypeDifferent;

    const shouldShowHeadphoneSwitch =
      !isEditNote &&
      !isMobile &&
      (recordingDetail.modality_type === "audio" || recordingDetail.modality_type === "dictate");

    const validateFields = () => {
      const { modality_type, note_type, description } = recordingDetail;
      const notesDescription = description ? description : notesRef.current?.resizableTextArea?.textArea.value || "";

      if (note_type === undefined || note_type === null) {
        message.error("Please select a note type");
        return false;
      }

      if (modality_type === "upload" && !audioFileRef.current) {
        message.error("Please upload an audio file");
        return false;
      }

      if (modality_type === "write" && notesDescription.length < 20) {
        message.error("Description should be at least 20 characters");
        return false;
      }

      return true;
    };

    const handleNoteUpdate = async () => {
      if (checkDemoPath()) {
        return;
      }
      if (showPaymentModal(userInfo)) {
        trackEvent(EventType.FREE_TRIAL_LIMIT_MODAL);
        handleOpenPaymentModal();
        dispatch(setCreateModalState(false));
        return;
      }
      const value = titleRef.current?.input?.value || "Untitled Session";
      const clientUuid = clients.find((c) => c.id === recordingDetail.client_id)?.uuid;

      if (needsRegeneration) {
        try {
          if (recordingDetail.note_type === undefined) {
            message.error("Please select a note type");
            return;
          }
          await handleWriteNoteSubmission({
            audio_id: selectedNoteForEditing?.id,
            title: value,
            note_type: recordingDetail.note_type,
            category_type: recordingDetail.category_type,
            modality_mode: recordingDetail.modality_type,
            gender_type: recordingDetail.gender_type,
            location_type: recordingDetail.location_type,
            duration_type: recordingDetail.duration_type,
            duration_addon_type: recordingDetail.duration_addon_type,
            client_id: recordingDetail.client_id,
          });
          if (clientUuid) {
            navigate(`${rootPath}/clients/${clientUuid}`);
          } else {
            navigate(`${rootPath}`);
          }
          dispatch(setCreateModalState(false));
          dispatch(setNotesViewVisible(false));
          dispatch(setCustomiseSectionState(false));
          resetRecordingState();
        } catch (error) {
          console.error("Error updating note:", error);
          Swal.fire({
            icon: "error",
            title: "Note submission failed.",
            text: "Please try again.",
          });
        }
      } else {
        if (
          selectedNoteForEditing &&
          !(
            selectedNoteForEditing &&
            selectedNoteForEditing?.category_type === recordingDetail.category_type &&
            selectedNoteForEditing?.client === recordingDetail.client_id &&
            selectedNoteForEditing?.duration_type === recordingDetail.duration_type &&
            selectedNoteForEditing?.duration_addon_type === recordingDetail.duration_addon_type &&
            selectedNoteForEditing?.location_type === recordingDetail.location_type &&
            selectedNoteForEditing?.note_type === recordingDetail.note_type &&
            selectedNoteForEditing?.title === value &&
            selectedNoteForEditing.modality_type.toString() === recordingDetail.modality_type.toString() &&
            selectedNoteForEditing.gender_type.toString() === recordingDetail.gender_type.toString()
          )
        ) {
          await updateNote({
            audio_id: selectedNoteForEditing.id,
            title: value,
            location_type: recordingDetail.location_type,
            duration_type: recordingDetail.duration_type,
            duration_addon_type: recordingDetail.duration_addon_type,
            client_id: recordingDetail.client_id,
          })
            .then(() => {
              message.success("Note updated successfully");
              if (clientUuid) {
                navigate(`${rootPath}/clients/${clientUuid}`);
              } else {
                navigate(`${rootPath}`);
              }
              dispatch(setCreateModalState(false));
              dispatch(setNotesViewVisible(false));
              dispatch(setCustomiseSectionState(false));
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          message.warning("Please update the note to save changes.");
        }
      }
    };

    const showErrorConnectionSwal = () => {
      Swal.fire({
        icon: "error",
        title: "Network issues detected in connection",
        text: "We are detecting network issues in connecting to our servers. Please try again by refreshing your browser.",
      });
    };

    const handleHeadphonesToggle = (checked: boolean) => {
      dispatch(setWithHeadphones(checked));
    };

    const fetchAudioInputDevices = async () => {
      const devices = await window.navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices
        .filter((device) => device.kind === "audioinput")
        .map((device) => ({
          deviceId: device.deviceId,
          groupId: device.groupId,
          label: device.label,
        }));
      return audioDevices;
    };

    const initiateMediaRecorder = async (withHeadphones = false, isDeviceChange = false) => {
      try {
        let stream: MediaStream;

        if (withHeadphones) {
          const micStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });

          // Check microphone status for headphone recording
          await checkMicrophoneMuteStatus(micStream);

          try {
            const displayStream = await navigator.mediaDevices.getDisplayMedia({
              audio: true,
            });

            const audioContext = new AudioContext();
            const micSource = audioContext.createMediaStreamSource(micStream);
            const displaySource = audioContext.createMediaStreamSource(displayStream);

            const destination = audioContext.createMediaStreamDestination();

            micSource.connect(destination);
            displaySource.connect(destination);

            // Use the combined stream
            stream = destination.stream;
          } catch {
            throw new Error(
              "Please be sure to select the browser tab of your telehealth session. If you've already selected the tab, refresh the page and try again.",
            );
          }
        } else {
          stream = await navigator.mediaDevices.getUserMedia({
            audio: {
              deviceId: recordingDevice?.deviceId ? { exact: recordingDevice.deviceId } : undefined,
              echoCancellation: false,
            },
            video: false,
          });

          // Check microphone status for regular recording
          await checkMicrophoneMuteStatus(stream);
        }

        // Verify that we have an active audio stream
        if (
          stream?.active &&
          stream.getAudioTracks().length > 0 &&
          stream.getAudioTracks().some((track) => track.enabled)
        ) {
          setStream(stream.getAudioTracks()[0]);
          const recordMimeType = determineBestAudioFormat();
          dispatch(setRecordMimeType(recordMimeType));

          const options = { recordMimeType, audioBitsPerSecond: 128000 };
          const newMediaRecorder = new MediaRecorder(stream, options);
          console.log("initiated new media recorder");
          dispatch(setMediaRecorder(newMediaRecorder));
          const track = stream.getAudioTracks()[0];
          const settings = track.getSettings();
          dispatch(
            setRecordingDevice({
              groupId: settings.groupId,
              deviceId: settings.deviceId,
              label: track.label,
            }),
          );
          setRetryCount(0);
          return newMediaRecorder;
        }
        throw new Error("No active audio stream detected. Please check your audio settings and try again.");
      } catch (err) {
        const error = err as Error;
        // See here for list of errors: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
        const recoverableErrors = [
          "AbortError",
          "OverconstrainedError",
          "NotReadableError",
          "NotFoundError",
          "InvalidStateError",
        ];
        if (recoverableErrors.includes(error.name)) {
          console.log(err);
          // This means the currently defined recordingDevice is no longer valid
          if (isDeviceChange && retryCount < 4) {
            // Reset state and useEffect will try to reinitialize
            // Only handle if this is a device change
            // Start of recording requires more configuration that isn't currently gracefully handled.
            // For example, doing this on initialization means timer won't start.
            setRetryCount(retryCount + 1);
            dispatch(setRecordingDevice(null));
            return null;
          } else {
            // This will continue and throw an error, user can restart and new recording
            // will work as expected.
            Sentry.captureException(err, {
              extra: {
                deviceId: recordingDevice?.deviceId,
                label: recordingDevice?.label,
                retryCount: retryCount,
              },
            });
            setRetryCount(0);
          }
        }

        let errorMessage = "We detected a problem with your recording setup. ";

        if (error.message.includes("Permission denied")) {
          errorMessage += "Please allow access to your microphone in your browser settings.";
        } else if (error.message.includes("No active audio stream")) {
          errorMessage += "No audio was detected. Please check your microphone or audio source.";
        } else {
          errorMessage += error.message;
          Sentry.captureException(error, {
            extra: {
              deviceId: recordingDevice?.deviceId,
              label: recordingDevice?.label,
            },
          });
        }
        Swal.fire({
          icon: "error",
          title: "Recording Setup Issue",
          text: errorMessage,
        });
        if (!isDeviceChange) {
          await handleRetry();
        }
        return null;
      }
    };

    const handleStartRecording = async () => {
      trackEvent(EventType.START_BUTTON, getCaptureDetails());
      await requestWakeLock();
      try {
        const newMediaRecorder = await initiateMediaRecorder(withHeadphones);
        if (newMediaRecorder) {
          newMediaRecorder.start(100);
          dispatch(setIsRecording(true));
          dispatch(setIsPaused(false));
          setRecordingTime(0);
          displayRecordingTimer();
        }
      } catch (error) {
        console.error("Error in initiating media recorder: ", error);
      }
    };

    const handleNewDevice = async () => {
      try {
        const newMediaRecorder = await initiateMediaRecorder(withHeadphones, true);
        if (newMediaRecorder && newMediaRecorder.stream?.active && !isPaused) {
          newMediaRecorder.start(100);
        }
      } catch (error) {
        console.error("Error in initiating media recorder: ", error);
      }
    };

    const handleUploadFile = async (client_id: number, client_uuid: string) => {
      const loading = message.loading("Uploading audio in background..", 0);
      const file = audioFileRef.current;
      const value = titleRef.current?.input?.value || "Untitled Session";
      if (!file) {
        message.error("No audio file selected");
        return;
      }

      try {
        if (recordingDetail.note_type === undefined) {
          message.error("Please select a note type");
          return;
        }
        const formData = new FormData();
        formData.append("audio", file);
        formData.append("title", value);
        formData.append("note_type", recordingDetail.note_type.toString());
        formData.append("category_type", recordingDetail.category_type.toString());
        formData.append("modality_mode", recordingDetail.modality_type);
        formData.append("language_type", recordingDetail.language_type.toString());
        formData.append("gender_type", recordingDetail.gender_type.toString());
        if (recordingDetail.location_type !== undefined && recordingDetail.location_type !== null)
          formData.append("location_type", recordingDetail.location_type.toString());
        if (recordingDetail.duration_type !== undefined && recordingDetail.duration_type !== null)
          formData.append("duration_type", recordingDetail.duration_type.toString());

        if (recordingDetail.duration_addon_type !== undefined && recordingDetail.duration_addon_type !== null)
          formData.append("duration_addon_type", recordingDetail.duration_addon_type.toString());

        if (client_id !== undefined && client_id !== null) formData.append("client_id", client_id?.toString());

        if (isLastPlusOneNote(userInfo)) {
          formData.append("is_locked", "true");
        }

        const response = await handleUploadAudioSubmission(formData);
        loading();

        console.log("File Upload Response:", response);
        if (response.status === 201) {
          message.success("Audio uploaded successfully. Processing in background..");
          trackEvent(EventType.UPLOAD_AUDIO_FINAL, {
            audioFileId: response?.data?.id,
          });
          eventEmitter.emit("noteCreated", response.data);
          navigate(`${rootPath}/clients/${client_uuid}`);
          resetRecordingState();
        } else {
          throw new Error("Audio upload failed");
        }
      } catch (error) {
        loading();
        console.error("Upload Error:", error);
        Swal.fire({
          icon: "error",
          title: "Audio upload and processing failed.",
          text: "Please try again.",
        });
        resetRecordingState();
      }
    };

    const handleDescribeSession = async (client_id: number, client_uuid: string) => {
      const title = titleRef.current?.input?.value || "Untitled Session";
      const description = notesRef.current?.resizableTextArea?.textArea.value || "";
      try {
        if (recordingDetail.note_type === undefined) {
          message.error("Please select a note type");
          return;
        }
        const response = await handleWriteNoteSubmission({
          note_text: description,
          title: title,
          note_type: recordingDetail?.note_type?.toString(),
          category_type: recordingDetail?.category_type?.toString(),
          modality_mode: recordingDetail.modality_type,
          gender_type: recordingDetail?.gender_type?.toString(),
          language_type: recordingDetail.language_type,
          location_type: recordingDetail.location_type,
          duration_type: recordingDetail.duration_type,
          duration_addon_type: recordingDetail.duration_addon_type,
          client_id: client_id,
          is_locked: isLastPlusOneNote(userInfo),
        });
        trackEvent(EventType.WRITE_AUDIO_FINAL, {
          audioFileId: response?.data?.id,
        });
        eventEmitter.emit("noteCreated", response.data);
        setSelectedClient(clients.find((c) => c.id === client_id));
        navigate(`${rootPath}/clients/${client_uuid}`);
        resetRecordingState();
      } catch (error) {
        console.error("Error submitting note:", error);
        Swal.fire({
          icon: "error",
          title: "Note submission failed.",
          text: "Please try again.",
        });
        resetRecordingState();
      }
    };

    const handleSubmitClient = async () => {
      let clientsFromServer: TClientForm[] = [];

      try {
        clientsFromServer = (await getAllClients()).data;
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        message.error("Failed to fetch clients");
      }

      const sortedClients = sortClientsByName(clientsFromServer);
      dispatch(setClients(sortedClients));

      const clientNameValidationResult = validateClientName(clientForm, clientsFromServer);

      if (clientNameValidationResult) {
        message.warning(clientNameValidationResult);
        return;
      }

      return createClient({
        ...clientForm,
        name: clientForm.name.trim(),
        diagnosis_ids: clientForm.diagnosis_ids
          ? clientForm.diagnosis_ids?.map((item) => Number.parseInt(item.toString()))
          : [],
      })
        .then((res) => {
          message.success("Client added successfully");
          getAllClients()
            .then((response) => {
              const sortedClients = sortClientsByName(response.data);
              dispatch(setClients(sortedClients));
            })
            .catch((error) => {
              console.error(error);
              message.error("Failed to fetch clients");
            });
          return res.data;
        })
        .catch((error) => {
          console.error(error);
          message.error("Failed to create client");
        });
    };

    const handleClientSelection = async () => {
      if (addClientView) {
        const newClient = await handleSubmitClient();
        if (!newClient) {
          message.error("Failed to create client");
          return [null, null];
        }
        return [newClient.id, newClient.uuid];
      }

      const client_id = recordingDetail.client_id;
      const clientUuid = clients.find((c) => c.id === client_id)?.uuid;

      if (!client_id && !addClientView) {
        message.error("Please select a client or choose to add a new one");
        return [null, null];
      }

      return [client_id, clientUuid];
    };

    const handleMainAction = async () => {
      if (checkDemoPath(true)) {
        trackEvent(EventType.CLICKED_ON_SUBMIT_NOTE_BEFORE_SIGNUP, {
          modalityMode: recordingDetail.modality_type,
        });
        return;
      }

      if (!validateFields()) {
        return;
      }

      if (startingSession) {
        // We're already starting initialization, wait for completion before trying again
        return;
      }

      setStartingSession(true);
      dispatch(setAudioDevices(await fetchAudioInputDevices()));

      const result = await handleClientSelection();
      if (!result || (result[0] === null && result[1] === null)) {
        setStartingSession(false);
        return;
      }

      const [client_id, clientUuid] = result;

      if (!clientUuid) {
        console.error("Client UUID not found");
        message.error("Client not found. Please try again.");
        setStartingSession(false);
        return;
      }

      const title = titleRef?.current?.input?.value || "";
      dispatch(
        setRecordingDetail({
          ...recordingDetail,
          title,
          client_id,
        }),
      );
      try {
        switch (recordingDetail.modality_type) {
          case "dictate":
          case "audio": {
            if (recordingDetail.note_type === undefined) {
              message.error("Please select a note type");
              return;
            }
            const response = await handleWriteNoteSubmission({
              title: title,
              note_type: recordingDetail?.note_type?.toString(),
              category_type: recordingDetail?.category_type?.toString(),
              modality_mode: recordingDetail.modality_type,
              gender_type: recordingDetail?.gender_type?.toString(),
              language_type: recordingDetail.language_type,
              location_type: recordingDetail.location_type,
              duration_type: recordingDetail.duration_type,
              duration_addon_type: recordingDetail.duration_addon_type,
              client_id: client_id,
              is_capture: true,
              system_metadata: {
                ...getSystemMetadata(),
                with_headphones: withHeadphones,
              },
            });

            if (response.status !== 201) {
              Swal.fire({
                icon: "error",
                title: "We detected a network error.",
                text: "Please refresh your browser and try again.",
              });
              resetRecordingState();
              return;
            }
            const initiateWebSocket = await initWebsocket(response.data.id);
            dispatch(
              setRecordingDetail({
                ...recordingDetail,
                title,
                client_id,
                current_recording_id: response.data.id,
              }),
            );

            eventEmitter.emit("refreshNotes");
            eventEmitter.emit("noteCreated", response.data);

            if (initiateWebSocket) {
              dispatch(setUploadType(0));
              dispatch(setCreateModalState(false));
              setStartingSession(false);
              dispatch(setShowRecordingView(true));
              await handleStartRecording();
            } else {
              throw new Error("WebSocket initialization failed");
            }
            break;
          }
          case "upload":
            await handleUploadFile(client_id, clientUuid);
            break;
          case "write":
            await handleDescribeSession(client_id, clientUuid);
            break;
          default:
            throw new Error("Invalid modality type");
        }

        // Navigate to the client page after started the note creation process
        navigate(`${rootPath}/clients/${clientUuid}`);
      } catch (error) {
        Sentry.captureException(error, {
          data: {
            title: title,
            client_id: client_id,
            note_type: recordingDetail?.note_type?.toString(),
          },
        });
        resetRecordingState();
        showErrorConnectionSwal();
      } finally {
        setStartingSession(false);
      }
    };

    const setModalityType = (modality_type: string) => {
      dispatch(
        setRecordingDetail({
          ...recordingDetail,
          modality_type,
        }),
      );
    };

    const saveDetailsForRetrieval = () => {
      const title = titleRef?.current?.input?.value || "";
      const description = notesRef.current?.resizableTextArea?.textArea.value || "";
      dispatch(
        setDemoState({
          ...demoState,
          clientForm,
          recordingDetails: {
            ...recordingDetail,
            title,
            clientId: recordingDetail.client_id,
            description: description,
          },
          isNoteModalOpen: true,
        }),
      );
    };

    const checkDemoPath = (isSubmit = false) => {
      if (isDemoPath) {
        if (isSubmit) {
          saveDetailsForRetrieval();
        }
        dispatch(setCreateModalState(false));
        dispatch(setShowDemoSignupModal(true));
      }
      return isDemoPath;
    };

    const scrollToBottom = () => {
      const scrollSection = document.getElementsByClassName("ant-drawer-body")[0];
      if (scrollSection) {
        scrollSection.scrollTo({
          top: scrollSection.scrollHeight,
          behavior: "smooth",
        });
      }
    };

    const resetState = () => {
      resetRecordingState(true);
      dispatch(setCreateModalState(false));
      dispatch(resetClientForm());
      dispatch(setAddClientView(false));
      setStartingSession(false);
    };

    const handleDrawerClose = () => {
      if (startingSession) {
        console.warn("Closed drawer before starting the recording");
        return;
      }
      resetState();
    };

    const renderNoteTypes = () => {
      if (isFromGWUL(userInfo)) {
        return <NoteTypeSelector noteTypes={GWUL_NOTE_TYPES} />;
      }

      if (isFromLLT(userInfo)) {
        return <NoteTypeSelector noteTypes={LLT_NOTE_TYPES} />;
      }

      if (isFromSOL(userInfo)) {
        return <NoteTypeSelector noteTypes={SOL_NOTE_TYPES} />;
      }

      return <NoteTypeSelector noteTypes={DEFAULT_NOTE_TYPES} />;
    };

    return (
      <Drawer
        destroyOnClose
        title={isEditNote ? "Edit Note" : "Create Note"}
        width={600}
        height={isMobile && !isMobilePath ? "80%" : "100%"}
        placement={isMobile ? "bottom" : "right"}
        open={isCreateModalVisible}
        onClose={handleDrawerClose}
        footer={
          isEditNote
            ? [
                <Button
                  key="update-note"
                  type="primary"
                  disabled={!isEditNote && !recordingDetail.title && !isCaptureEnabled}
                  className={styles[`${componentName}__modal-button-primary`]}
                  onClick={handleNoteUpdate}
                >
                  Update Note
                </Button>,
              ]
            : [
                <Button
                  key="create-note"
                  loading={startingSession}
                  disabled={startingSession}
                  type="primary"
                  className={styles[`${componentName}__modal-button-primary`]}
                  onClick={handleMainAction}
                >
                  {recordingDetail?.modality_type === "write" && "Generate Note"}
                  {recordingDetail?.modality_type === "upload" && "Generate Note"}
                  {recordingDetail?.modality_type === "dictate" && "Start Dictation"}
                  {recordingDetail?.modality_type === "audio" && "Start Session"}
                </Button>,
              ]
        }
      >
        <Row gutter={[16, 16]}>
          {!isEditNote && (
            <>
              <RowHeader title="CHOOSE MODE" />
              <Col span={24}>
                <Flex wrap="wrap" gap={"10px 0px"}>
                  {MODALITY_LIST.map((modality) => (
                    <SelectableBadge
                      key={modality.type}
                      count={modality.title}
                      icon={modality.icon}
                      disabled={recordingDetail.note_type === NoteType.CONSULT_NOTE && modality.type !== "write"}
                      selected={recordingDetail.modality_type === modality.type}
                      onClick={() => {
                        setModalityType(modality.type);
                      }}
                    />
                  ))}
                </Flex>
              </Col>
            </>
          )}
          {shouldShowHeadphoneSwitch ? (
            <Col span={24} className={`${styles[`${componentName}__modal-row`]}`}>
              <div className={styles[`${componentName}__modal-row-header`]}>
                <Typography>USING HEADPHONES</Typography>
              </div>
              <Flex align="center" style={{ marginTop: 8 }} gap={8}>
                <Typography.Text>No</Typography.Text>
                <Switch checked={withHeadphones} onChange={handleHeadphonesToggle} />
                <Typography.Text>Yes</Typography.Text>
              </Flex>
              <a
                href="https://getnudgeai.com/headphones-faq"
                target="_blank"
                style={{
                  marginTop: 8,
                  fontSize: 14,
                }}
                rel="noreferrer"
              >
                How to? (supported browsers: Chrome, Microsoft Edge)
              </a>
            </Col>
          ) : null}
          <Col span={24}>
            <div className={styles[`${componentName}__modal-row-header`]}>
              <Typography>CHOOSE NOTE TYPE</Typography>
            </div>
          </Col>
          {renderNoteTypes()}
          <RowHeader title="ADD SESSION DETAILS" />
          {!addClientView ? (
            <ClientSelector />
          ) : (
            <Col span={24} className={`${styles[`${componentName}__modal-row`]}`}>
              <Card
                title="Add Client"
                id="special-card"
                extra={
                  <CloseOutlined
                    onClick={() => {
                      dispatch(setAddClientView(false));
                    }}
                  />
                }
              >
                <ClientForm />
              </Card>
            </Col>
          )}
          <Col span={24} className={`${styles[`${componentName}__modal-row`]}`}>
            <Typography.Text>Title: (optional)</Typography.Text>
            <Input
              defaultValue={recordingDetail.title}
              ref={titleRef}
              className={`${styles[`${componentName}__modal-row-field`]}`}
              placeholder="Note Title"
            />
          </Col>
          <LocationSelector />
          <CPTCodeSelector />
          {recordingDetail.modality_type === "write" && (
            <Col span={24} className={`${styles[`${componentName}__modal-row`]}`}>
              <Typography.Text>
                {recordingDetail.note_type === NoteType.CONSULT_NOTE
                  ? `Clinical Description`
                  : `Describe Session / Import old note:`}
              </Typography.Text>
              <TextArea
                defaultValue={recordingDetail.description}
                ref={notesRef}
                onFocus={scrollToBottom}
                autoSize={{ minRows: 12, maxRows: 15 }}
                placeholder={
                  recordingDetail.note_type === 13
                    ? "Enter your clinical description of the patient and reason for referral."
                    : "Describe your session with writing or copy paste an old note to generate a new clean note."
                }
                rows={4}
              />
            </Col>
          )}
          {recordingDetail.modality_type === "upload" && <FileUploader audioFileRef={audioFileRef} />}
        </Row>
      </Drawer>
    );
  },
);

export default CreateNoteModal;
