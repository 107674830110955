import { SearchOutlined } from "@ant-design/icons";
import { Avatar, Col, Flex, Input, Row, Spin, Table, Typography } from "antd";
import React, { useState } from "react";

import type { TableColumnsType, TableProps } from "antd";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { CustomLabel, CustomizedLegend } from "../../components/Analytics/analytics.components";
import { useIsMobileView } from "../../hooks/ui-hook";
import {
  AnalyticsObject,
  useGetDiagnosesBreakdownQuery,
  useGetUserAnalyticsQuery,
} from "../../service/analytics.service";
import { getFirstLetterOfNameAndSurname } from "../../utils/datamanipulation.utils";
import { formatSeconds, getRandomAvatarColor } from "../../utils/ui.utils";
import styles from "./analytics.module.scss";

const PIECHART_COLORS = [
  "#ed651b",
  "#ff813c",
  "#ff9156",
  "#ff9e69",
  "#ffd1a7",
  "#2b4ded",
  "#748dff",
  "#8a9eff",
  "#b8c5ff",
  "#d7ddff",
  "#ecf0ff",
];

const columns: TableColumnsType<AnalyticsObject> = [
  {
    key: "index",
    render: (_text, _record, index) => (
      <Flex align="center" justify="center" style={{ padding: 6 }}>
        {index + 1}
      </Flex>
    ),
  },
  {
    title: "Name",
    dataIndex: "name",
    width: 350,
    render: (value, record) => {
      return (
        <Flex align="center" gap={16}>
          <Avatar
            size={40}
            style={{
              backgroundColor: getRandomAvatarColor(value),
            }}
          >
            {getFirstLetterOfNameAndSurname(value)}
          </Avatar>
          <Flex vertical>
            <Typography.Text strong>{value}</Typography.Text>
            <Typography.Text>{record.email}</Typography.Text>
          </Flex>
        </Flex>
      );
    },
  },
  {
    title: "Clients Created (Per Month)",
    dataIndex: "number_of_clients_created_per_month",
    width: 150,
    render: (value) => {
      return (
        <Flex align="center">
          <span>{value}</span>
        </Flex>
      );
    },
  },
  {
    title: "Total Notes (7-day Avg.)",
    dataIndex: "total_number_of_notes_created",
    render: (value) => {
      return value;
    },
  },
  {
    title: "Intakes Created (Per Month)",
    dataIndex: "number_of_intakes_created_per_month",
    width: 150,
    render: (value) => {
      return (
        <Flex align="center">
          <span>{value}</span>
        </Flex>
      );
    },
  },
  {
    title: "Active Patients",
    dataIndex: "number_of_ongoing_clients",
    render: (value) => {
      return value;
    },
  },
  {
    title: "Avg Time Per Session",
    dataIndex: "average_time_per_session",
    width: 150,
    render: (value) => {
      if (!value) {
        return "";
      }
      return formatSeconds(value);
    },
  },
  {
    title: "Last Note Created On",
    dataIndex: "last_note_created_on",
    width: 175,
    render: (value) => {
      return dayjs(value).format("MMM D, YYYY");
    },
  },
  {
    title: "Joined On",
    dataIndex: "joined_on",
    render: (value) => {
      return dayjs(value).format("MMM D, YYYY");
    },
  },
];

const AnalyticsPage = () => {
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const { data: analytics, isLoading, isFetching } = useGetUserAnalyticsQuery({ page, search: searchValue });

  const { data: diagnosesData, isLoading: diagnosesDataLoading } = useGetDiagnosesBreakdownQuery();

  const isMobile = useIsMobileView();

  const componentName = "analytics";

  // Create a debounced version of setSearchValue
  const debouncedSetSearchValue = debounce((value) => setSearchValue(value), 600);

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    debouncedSetSearchValue(event.target.value);
  };

  const handleTableChange: TableProps["onChange"] = (pagination) => {
    setPage(pagination.current as number);
  };

  return (
    <Row>
      <Col span={24} className={styles[`${componentName}__container-right`]}>
        <Flex justify="space-between" align="center" className={styles[`${componentName}__container-header`]}>
          <Typography className={styles[`${componentName}__container-title`]}>Org Members</Typography>
          <Flex align="center" gap={12}>
            <Input
              placeholder="Search for therapists"
              onChange={handleSearch}
              prefix={<SearchOutlined />}
              size="small"
              style={{ padding: "4px 12px", borderRadius: 6 }}
            />
          </Flex>
        </Flex>
        <Flex vertical align="flex-start" className={styles[`${componentName}__container-chart`]}>
          <Typography className={styles[`${componentName}__container-chart-title`]}>Patient Diagnosis Split</Typography>
          {diagnosesDataLoading ? (
            <Flex style={{ width: "100%", height: "100%" }} align="center" justify="center">
              <Spin />
            </Flex>
          ) : null}
          {diagnosesData && !diagnosesDataLoading ? (
            <ResponsiveContainer maxHeight={250}>
              <PieChart>
                <Pie
                  data={diagnosesData}
                  dataKey="value"
                  cx={100}
                  cy={110}
                  innerRadius={55}
                  outerRadius={95}
                  stroke="none"
                >
                  {diagnosesData.map((_entry, index) => (
                    <Cell key={`cell-${index}`} fill={PIECHART_COLORS[index % PIECHART_COLORS.length]} />
                  ))}
                  <Label
                    content={
                      <CustomLabel
                        value={diagnosesData.reduce((accumulator, currentItem) => accumulator + currentItem.value, 0)}
                      />
                    }
                    position="center"
                  />
                </Pie>
                {isMobile ? (
                  <Tooltip />
                ) : (
                  <Legend
                    wrapperStyle={{
                      right: -10,
                      bottom: -40,
                      width: "max-content",
                    }}
                    content={<CustomizedLegend />}
                  />
                )}
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <Flex style={{ width: "100%", height: "100%" }} align="center" justify="center">
              <Typography.Text type="secondary" strong>
                No data
              </Typography.Text>
            </Flex>
          )}
        </Flex>
        <Table
          columns={columns}
          dataSource={analytics?.results}
          loading={isLoading || isFetching}
          onChange={handleTableChange}
          size="middle"
          pagination={{
            total: analytics?.count,
          }}
        />
      </Col>
    </Row>
  );
};

export default AnalyticsPage;
