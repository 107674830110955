import type { PayloadAction } from "@reduxjs/toolkit";
import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import type { AxiosError } from "axios";
import axiosInstance from "../api/axiosInstance";
import { getUserInfo, handleChangePassword } from "../service/auth.service";

type User = {
  email: string;
  password: string;
};

type NewUser = User & {
  name: string;
  invitation?: string;
};

type UserBasicInfo = {
  id: string;
  name: string;
  email: string;
};

export enum UserTier {
  FREE = 0,
  PRO = 1,
  GROUP = 2,
}

export type UserInfoProps = {
  total_recordings: number;
  free_recordings_limit: number;
  is_paying: boolean;
  plan: number;
  tier: UserTier;
  group_name: string;
  is_group_admin: boolean;
  is_tutorial_completed?: boolean;
  is_transcript?: boolean;
  created_at?: string;
  free_trial_days_limit: number;
  enable_analytics: boolean;
  group_logo: string | null;
  onboarding_data?: {
    profession?: string;
    practice_size?: number;
    referral_source?: string;
    completed?: boolean;
    start_time?: string;
    reward_claimed?: boolean;
  };
};

type AuthApiState = {
  basicUserInfo?: UserBasicInfo | null;
  status: "idle" | "loading" | "failed";
  error: string | null;
  userInfo: UserInfoProps;
};

const initialState: AuthApiState = {
  basicUserInfo: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo") as string) : null,
  status: "idle",
  error: null,
  userInfo: {
    total_recordings: -1,
    free_recordings_limit: 0,
    is_paying: false,
    plan: 0,
    tier: 0,
    group_name: "",
    is_group_admin: false,
    free_trial_days_limit: 0,
    enable_analytics: false,
    group_logo: null,
  },
};

export const login = createAsyncThunk("login", async (data: User) => {
  const loginData = {
    username: data.email,
    password: data.password,
    email: data.email,
  };
  const response = await axiosInstance.post("/rest-auth/login/", loginData);
  const resData = response.data;

  localStorage.setItem("token", resData.key);

  return resData;
});

export const register = createAsyncThunk("register", async (data: NewUser) => {
  const dataTest = {
    first_name: data.name,
    email: data.email,
    password1: data.password,
    password2: data.password,
    username: data.email,
    invitation: data.invitation,
  };

  try {
    const response = await axiosInstance.post("/rest-auth/registration/", JSON.stringify(dataTest));
    const resData = response.data;

    localStorage.setItem("token", resData.key);

    return resData;
  } catch (error: unknown) {
    console.error(error);
    const axiosError = error as AxiosError<{ username?: string[]; detail?: string }>;

    if (axiosError.response?.data?.username?.[0]?.includes("A user with that username already exists.")) {
      message.error("The email is already in use. Please try a different email.");
    } else if (axiosError.response?.data?.detail) {
      message.error(axiosError.response?.data?.detail);
    } else {
      message.error("Registration failed. Please try again later.");
    }
  }
});

export const logout = createAsyncThunk("logout", async () => {
  // TODO: This endpoint does not exist
  const response = await axiosInstance.post("/logout", {});
  const resData = response.data;
  localStorage.removeItem("userInfo");
  return resData;
});

export const getUser = createAsyncThunk("users/profile", async (userId: string) => {
  const response = await axiosInstance.get(`/users/${userId}`);
  return response.data;
});

export const updateUserInfo = createAsyncThunk("auth/updateUserInfo", async () => {
  const response = await getUserInfo();
  return response.data;
});

export const changePassword = createAsyncThunk(
  "password/change",
  async (data: { email: string; newPassword: string }) => {
    const passwordResetData = {
      new_password1: data.newPassword,
      new_password2: data.newPassword,
    };
    const response = await handleChangePassword(passwordResetData);
    const resData = response.data;
    return resData;
  },
);

// Create an action to reset basicUserInfo
export const resetBasicUserInfo = createAction("auth/resetBasicUserInfo");

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetBasicUserInfo: (state) => {
      state.basicUserInfo = null;
      state.userInfo = {
        total_recordings: -1, // setting this to -1 because Intercom should open when there are 0 notes and the default notes is 0, so it's triggering the widget even before the API returns the response
        free_recordings_limit: 0,
        is_paying: false,
        plan: 0,
        tier: 0,
        group_name: "",
        is_group_admin: false,
        free_trial_days_limit: 0,
        enable_analytics: false,
        group_logo: null,
      };
    },
    setUserInfo: (state, action: PayloadAction<UserInfoProps>) => {
      state.userInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<UserBasicInfo>) => {
        state.status = "idle";
        state.basicUserInfo = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Login failed";
      })

      .addCase(register.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(register.fulfilled, (state, action: PayloadAction<UserBasicInfo>) => {
        state.status = "idle";
        state.basicUserInfo = action.payload;
      })
      .addCase(register.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Registration failed";
      })

      .addCase(logout.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.status = "idle";
        state.basicUserInfo = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Logout failed";
      })

      .addCase(getUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getUser.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(getUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Get user profile data failed";
      })

      .addCase(changePassword.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Change password failed";
      })
      .addCase(updateUserInfo.fulfilled, (state, action) => {
        state.userInfo = action.payload;
      })
      .addCase(updateUserInfo.rejected, (state, action) => {
        state.error = action.error.message || "Failed to update user info";
        console.error("Failed to update user info:", action.payload);
      });
  },
});

export const { setUserInfo } = authSlice.actions;

export default authSlice.reducer;
