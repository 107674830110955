import { AxiosResponse } from "axios";
import axiosInstance from "../api/axiosInstance";
import { TClientForm } from "../types/index.type";

const createClient = async (client: TClientForm) => {
  return axiosInstance.post(`/client/`, client);
};

const getAllClients = async (): Promise<AxiosResponse<TClientForm[]>> => {
  return axiosInstance.get(`/client/list/`);
};

const updateClient = (clientId: number, client: TClientForm) => {
  return axiosInstance.put(`/client/${clientId}/`, client);
};

const deleteClient = (clientId: number) => {
  return axiosInstance.delete(`/client/${clientId}/`);
};

const getClientDetails = (clientId: number) => {
  return axiosInstance.get(`/client/${clientId}/`);
};

const getUsersClientProgressSummary = (clientId: number) => {
  return axiosInstance.post(`/client/progress_summary/`, {
    id: clientId,
  });
};

const getAllDcmCodes = async () => {
  return axiosInstance.get(`/client/dsm/`);
};

const validateClientName = (clientForm: TClientForm, clientsList: TClientForm[]) => {
  // Check if the client name field is empty
  if (clientForm.name === "") {
    return "Please enter client name";
  }

  // Check for special characters in the name and ensure it starts with a letter
  const regex = /^[a-zA-Z][a-zA-Z0-9\s.,'-]*$/;
  if (!regex.test(clientForm.name.trim())) {
    return "Client name must start with a letter and can only contain letters, numbers, spaces, and the following characters: . , ' -";
  }

  // Search for an existing client with the same name
  const existingClientWithTheSameName = clientsList.find(
    (client) => client.name.toLowerCase().trim() === clientForm.name.toLowerCase().trim(),
  );

  // Return an error message if a client with the same name exists
  return existingClientWithTheSameName
    ? "There's already a client with the same name. Please use a different name."
    : undefined;
};

const getClientDetailsByUUID = (uuid: string) => {
  return axiosInstance.get(`/client/uuid/${uuid}/`);
};

export {
  createClient,
  deleteClient,
  getAllClients,
  getAllDcmCodes,
  getClientDetails,
  getClientDetailsByUUID,
  getUsersClientProgressSummary,
  updateClient,
  validateClientName,
};
