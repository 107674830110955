import { Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/redux-hooks";
import { CUSTOMISE_ICON } from "../../pages/notes/NotesHeader.component";
import { setCustomiseSectionState } from "../../slices/appStateSlice";
import { setNoteTypeInstructionNote } from "../../slices/clientSlice";

type CustomiseNoteCTAProps = {
  text: string;
  buttonText?: string;
};

const CustomiseNoteCTA: React.FC<CustomiseNoteCTAProps> = ({ text, buttonText = "Customize" }) => {
  const dispatch = useDispatch();
  const { notesDetails } = useAppSelector((state) => state.notes);

  const handleClick = () => {
    if (notesDetails?.note_type !== undefined) {
      dispatch(
        setNoteTypeInstructionNote({
          [notesDetails.note_type]: {
            note: text,
            is_system: false,
          },
        }),
      );
    }
    dispatch(setCustomiseSectionState(true));
  };

  return (
    <Button type="default" icon={CUSTOMISE_ICON} className={`mobile-action-button primary`} onClick={handleClick}>
      {buttonText}
    </Button>
  );
};

export default CustomiseNoteCTA;
