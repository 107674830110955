import { Col, Typography } from "antd";
import styles from "./createnotemodal.module.scss";

const RowHeader = ({ title = "" }: { title: string }) => {
  const componentName = "createnotemodal";
  return (
    <Col span={24}>
      <div className={styles[`${componentName}__modal-row-header`]}>
        <Typography>{title}</Typography>
      </div>
    </Col>
  );
};

export default RowHeader;
