import { useCallback, useEffect } from "react";
import { useIntercom } from "react-use-intercom";
import { isEligibleForOnboarding } from "../domain/user.domain";
import { useAppSelector } from "./redux-hooks";
import { useIsMobileView } from "./ui-hook";

const useIntercomWithData = () => {
  const isMobile = useIsMobileView();
  const user = useAppSelector((state) => state.user);
  const { userInfo } = useAppSelector((state) => state.auth);
  const { isCreateModalVisible, isAITemplateModalVisible, isCreateClientDrawerVisible } = useAppSelector(
    (state) => state.appState,
  );
  const { recordingDetail } = useAppSelector((state) => state.recordings);
  const { boot, shutdown, showNewMessage } = useIntercom();

  const bootWithData = useCallback(() => {
    if (!window?.Intercom || typeof window?.Intercom !== "function") return;

    const userType = userInfo.group_name ? "group_user" : userInfo.is_paying ? "pro_user" : "unpaid_user";

    boot({
      userId: user.data?.id,
      email: user.data?.email,
      name: user.data?.name,
      userHash: user.data?.intercom_user_hash,
      customAttributes: {
        user_type: userType,
        group_name: userInfo.group_name || undefined,
        is_paying: userInfo.is_paying,
        total_recordings: userInfo.total_recordings,
      },
    });
  }, [boot, user.data?.id, user.data?.email, user.data?.name, user.data?.intercom_user_hash, userInfo]);

  useEffect(() => {
    // Only show Intercom for non-paying users and group users
    const shouldShowIntercom = !userInfo.is_paying || userInfo.group_name;
    const isRecordingActive = recordingDetail?.current_recording_id;

    // Hide Intercom when any drawer is open or during recording
    if (isCreateModalVisible || isRecordingActive || isAITemplateModalVisible || isCreateClientDrawerVisible) {
      shutdown();
      return;
    }

    if (shouldShowIntercom) {
      bootWithData();

      // Check if the user is a new unpaid user who completed onboarding
      // and hasn't been shown the Intercom message yet
      const hasSeenIntercom = localStorage.getItem(`intercom_shown_${user.data?.id}`) || false;
      const isNewUser = userInfo.total_recordings === 0;
      const isNotOnboarding = !isEligibleForOnboarding(userInfo);

      // Only auto-show on desktop for new unpaid users after completing onboarding
      // and only if they haven't been shown the message before
      if (!isMobile && isNewUser && isNotOnboarding && !hasSeenIntercom) {
        // Show message after a short delay
        setTimeout(() => {
          showNewMessage();
          // Mark that this user has seen the Intercom message
          localStorage.setItem(`intercom_shown_${user.data?.id}`, "true");
        }, 3000);
      }
    } else {
      shutdown();
    }
  }, [
    userInfo,
    bootWithData,
    showNewMessage,
    shutdown,
    isMobile,
    isCreateModalVisible,
    recordingDetail,
    isAITemplateModalVisible,
    isCreateClientDrawerVisible,
    user.data?.id,
  ]);

  return { bootWithData, shutdown };
};

export default useIntercomWithData;
