import { Typography } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import type { CredentialResponse } from "@react-oauth/google";
import { Button, Col, Divider, Flex, Form, Input, Row } from "antd";
import { Card } from "antd";
import { jwtDecode } from "jwt-decode";
import type React from "react";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axiosInstance from "../../api/axiosInstance";
import HippaLogo from "../../assets/bg/hippa.png";
import LoadingModal from "../../components/LoadingModal";
import { useRootPath } from "../../hooks/app.hook";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { patchUserInfo } from "../../service/auth.service";
import { setIsDemoPath } from "../../slices/appStateSlice";
import { register } from "../../slices/authSlice";
import { fetchUserData } from "../../slices/userSlice";
import { EventType, identifyUser, trackEvent } from "../../utils/analytics";
import MarketingCopy from "./MarketingCopy";
import styles from "./auth.module.scss";

interface UtmParams {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

export interface DecodedGoogleCredential {
  email: string;
  name: string;
  picture: string;
}

const Register: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const componentName = "login";
  const dispatch = useAppDispatch();
  const { rootPath } = useRootPath();
  const [isLoading, setIsLoading] = useState(false);
  const [invitationDetails, setInvitationDetails] = useState<{
    email?: string;
    group_name?: string;
    inviter_name?: string;
  } | null>(null);
  const [utmParams, setUtmParams] = useState<UtmParams>({});

  const { isDemoPath } = useAppSelector((state) => state.appState);

  // Get invitation key from URL query params
  const searchParams = new URLSearchParams(location.search);
  const invitationKey = searchParams.get("invitation");

  useEffect(() => {
    if (invitationKey) {
      // Validate invitation and get details
      axiosInstance
        .get(`/users/check-invitation/?key=${invitationKey}`)
        .then((response) => {
          setInvitationDetails(response.data);
          // Pre-fill email field if it matches invitation
          form.setFieldsValue({ email: response.data.email });
        })
        .catch((error) => {
          if (error.response?.status === 404) {
            Swal.fire({
              title: "Invalid Invitation",
              icon: "error",
              text: "This invitation link is invalid or has expired.",
            });
            navigate("/register");
          }
        });
    }
  }, [invitationKey, form, navigate]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params: UtmParams = {};

    const utmKeys = ["source", "medium", "campaign", "term", "content"];
    for (const param of utmKeys) {
      const value = searchParams.get(`utm_${param}`);
      if (value) {
        params[`utm_${param}` as keyof UtmParams] = value;
      }
    }

    setUtmParams(params);
  }, [location]);

  const handleRegister = async () => {
    setIsLoading(true);

    const { name, email, password } = form.getFieldsValue();

    try {
      const registrationData = invitationKey
        ? { name, email, password, invitation: invitationKey }
        : { name, email, password };

      const registerationSuccessful = await dispatch(register(registrationData)).unwrap();

      if (registerationSuccessful) {
        dispatch(setIsDemoPath(false));
        identifyUser(email, { email });
        window?.fbq("track", "CompleteRegistration");
        trackEvent(EventType.REGISTER, { email });
        dispatch(fetchUserData());
        const token = localStorage.getItem("token");
        if (!token) {
          setIsLoading(false);
          return;
        }
        if (invitationDetails) {
          Swal.fire({
            title: "Welcome!",
            icon: "success",
            text: `Successfully registered and joined ${invitationDetails.group_name}`,
          });
        }
        if (utmParams) {
          await patchUserInfo({ utm_data: utmParams });
        }
        navigate(rootPath);
      }
    } catch (e) {
      console.error(e);
      Swal.fire({
        title: "Registration Failed",
        icon: "error",
        text: "There was an error during registration. Please try again.",
      });
    }

    setIsLoading(false);
  };

  const handleGoogleSignUp = async (credentialResponse: CredentialResponse) => {
    if (!credentialResponse.credential) return;

    const decoded: DecodedGoogleCredential = jwtDecode(credentialResponse.credential);

    try {
      const response = await axiosInstance.post("/accounts/google/login/", {
        access_token: credentialResponse.credential,
        id_token: credentialResponse.credential,
        invitation: invitationKey,
      });

      if (response.data.key) {
        localStorage.setItem("token", response.data.key);
        dispatch(setIsDemoPath(false));
        dispatch(fetchUserData());
        identifyUser(decoded.email, { email: decoded.email });
        trackEvent(EventType.GOOGLE_SIGN_UP, { email: decoded.email });
        if (invitationDetails) {
          Swal.fire({
            title: "Welcome!",
            icon: "success",
            text: `Successfully registered and joined ${invitationDetails.group_name}`,
          });
        }
        if (utmParams) {
          await patchUserInfo({ utm_data: utmParams });
        }
        navigate(rootPath);
      }
    } catch (error) {
      console.error("Google sign up error:", error);
      Swal.fire({
        title: "Sign Up Error",
        icon: "error",
        text: "It seems you might have an existing account. Please try signing in with your email or password instead. If you can't remember your password, you can use the 'Forgot Password' option.",
      });
    }
  };

  const inputElementFocused = (event: React.FocusEvent<HTMLInputElement>) => {
    if (isDemoPath) {
      const { id } = event.target;
      if (id === "name") {
        trackEvent(EventType.CLICKED_ON_FULL_NAME_DEMO_PATH);
      } else if (id === "email") {
        trackEvent(EventType.CLICKED_ON_EMAIL_DEMO_PATH);
      } else if (id === "password") {
        trackEvent(EventType.CLICKED_ON_PASSWORD_DEMO_PATH);
      }
    }
  };

  return (
    <>
      {isLoading && <LoadingModal />}
      <Row className="fullScreen auth-container">
        <Col xs={24} sm={24} md={12} lg={12} className={styles.marketingSection}>
          <MarketingCopy />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} className={styles.formSection}>
          <Card className={styles.formCard}>
            <Flex vertical justify="center" align="center">
              <Typography variant="h5" className={styles.formTitle}>
                {invitationDetails ? `Join ${invitationDetails.group_name}` : "Create Your Free Nudge Account"}
              </Typography>
              {!invitationDetails && (
                <>
                  <Typography className={styles.formSubtitle}>Get 5 free sessions with your trial.</Typography>
                  <Typography className={styles.formSubtitle}>No credit card required.</Typography>
                </>
              )}
              {invitationDetails && (
                <Typography className={styles.formSubtitle} style={{ marginBottom: 10 }}>
                  {invitationDetails.inviter_name} has invited you to join their team
                </Typography>
              )}
              {!invitationDetails ? (
                <>
                  <div
                    style={{
                      marginTop: 10,
                      boxSizing: "border-box",
                    }}
                  >
                    <GoogleLogin
                      onSuccess={handleGoogleSignUp}
                      text="signup_with"
                      onError={() => {
                        console.error("Google Sign Up Failed");
                        Swal.fire({
                          title: "Sign Up Error",
                          icon: "error",
                          text: "It seems you might have an existing account. Please try signing in with your email or password instead. If you can't remember your password, you can use the 'Forgot Password' option.",
                        });
                      }}
                    />
                  </div>
                  <Divider style={{ width: "100%", margin: "10px 0px" }}>OR</Divider>
                </>
              ) : null}
              <Form
                className={styles.formContainer}
                form={form}
                layout="vertical"
                onFinish={handleRegister}
                autoComplete="off"
              >
                <Form.Item
                  name="name"
                  label="Full Name"
                  rules={[{ required: true, message: "Please enter your name." }]}
                >
                  <Input placeholder="Susan Johnson" onFocus={inputElementFocused} />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email.",
                    },
                    {
                      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Please ensure a valid email address.",
                    },
                    {
                      validator: async (_, value) => {
                        if (invitationDetails && value !== invitationDetails.email) {
                          throw new Error(`Please use the invited email: ${invitationDetails.email}`);
                        }
                      },
                    },
                  ]}
                >
                  <Input placeholder="susan@example.com" onFocus={inputElementFocused} disabled={!!invitationDetails} />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    { required: true, message: "Please enter your password." },
                    {
                      min: 5,
                      message: "Password must be at least 5 characters long",
                    },
                  ]}
                >
                  <Input.Password placeholder="Enter password" type="password" onFocus={inputElementFocused} />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" style={{ width: "100%", marginTop: "10px" }}>
                    {invitationDetails ? "Join Team" : "Create Free Account"}
                  </Button>
                </Form.Item>
              </Form>
              <div
                style={{
                  fontSize: 12,
                  textAlign: "center",
                }}
              >
                By signing up or logging into Nudge, you are agreeing to our
                <NavLink to={"/privacy"}> Privacy Policy</NavLink> and
                <NavLink to={"/terms"}> Terms of Service</NavLink>,
                <a target="_blank" href="https://getnudgeai.com/baa" rel="noopener noreferrer">
                  {" "}
                  Business Associate Agreement
                </a>
                .
              </div>
              <Flex
                className={styles[`${componentName}__form-container-text`]}
                justify="center"
                style={{
                  marginTop: "10px",
                }}
              >
                Already a User? &nbsp;<NavLink to={`/login${location.search}`}>Login</NavLink>
              </Flex>
              <div className={styles[`${componentName}__form-container-bottom-notification`]}>
                Your data is protected with HIPAA-compliant encryption. <br /> Nudge removes any personally identifying
                data, including audio.
              </div>
              <img src={HippaLogo} alt="Hippa" className={styles[`${componentName}__side-image-hippa`]} />
            </Flex>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Register;
