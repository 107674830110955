import { CheckCircleFilled } from "@ant-design/icons";
import { Button, Card, Flex, Result, Typography } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;

const ThankYou = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window?.fbq("track", "Purchase");
  }, []);

  const handleContinue = () => {
    navigate("/home");
  };

  return (
    <Flex
      vertical
      align="center"
      justify="center"
      style={{
        minHeight: "100vh",
        background: "linear-gradient(135deg, #f5f7fa 0%, #e4e7eb 100%)",
        padding: "20px",
      }}
    >
      <Card
        style={{
          width: "100%",
          maxWidth: 600,
          borderRadius: 16,
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
          animation: "fadeIn 0.5s ease-in-out",
        }}
        bodyStyle={{ padding: "48px 24px" }}
      >
        <Result
          icon={<CheckCircleFilled style={{ fontSize: 72, color: "#52c41a" }} />}
          title={
            <Title level={2} style={{ color: "#2b4ded", marginBottom: 24 }}>
              Payment Successful!
            </Title>
          }
          subTitle={
            <Flex vertical gap={16} align="center">
              <Text style={{ fontSize: 18, color: "#4b5563" }}>
                Thank you for subscribing to Nudge AI. Your account has been upgraded successfully.
              </Text>
              <Button
                type="primary"
                size="large"
                onClick={handleContinue}
                style={{
                  marginTop: 16,
                  backgroundColor: "#2b4ded",
                  height: 48,
                  fontSize: 16,
                  paddingLeft: 32,
                  paddingRight: 32,
                }}
              >
                Go to dashboard
              </Button>
            </Flex>
          }
        />
      </Card>
      <style>
        {`
          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
      </style>
    </Flex>
  );
};

export default ThankYou;
