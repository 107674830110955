type EventHandler = (...args: unknown[]) => void;

interface EventMap {
  [eventName: string]: EventHandler[];
}

class EventEmitter {
  private events: EventMap = {};

  public on(eventName: string, handler: EventHandler): void {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }
    this.events[eventName].push(handler);
  }

  public off(eventName: string, handler: EventHandler): void {
    if (!this.events[eventName]) {
      return;
    }
    this.events[eventName] = this.events[eventName].filter((h) => h !== handler);
  }

  public emit(eventName: string, ...args: unknown[]): void {
    if (!this.events[eventName]) {
      return;
    }
    for (const handler of this.events[eventName]) {
      handler(...args);
    }
  }
}

export const eventEmitter = new EventEmitter();
