import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useAppDispatch } from "../../../hooks/redux-hooks";
import { setAITemplateModalState } from "../../../slices/appStateSlice";
import styles from "./AITemplateModal.module.scss";
import List from "./List";
import { CustomNote, TabType } from "./types";

const ListContainer = ({
  setSelectedTab,
  handleEditTemplate,
  customNoteList,
  fetchCustomNoteList,
  isListLoading,
}: {
  setSelectedTab: (tab: TabType) => void;
  setIsPrimaryButtonDisabled?: (disabled: boolean) => void;
  handleEditTemplate: (template: CustomNote) => void;
  isListLoading: boolean;
  customNoteList: CustomNote[];
  fetchCustomNoteList: () => void;
}) => {
  const componentName = "ai-template-modal";
  const dispatch = useAppDispatch();

  function Header() {
    return (
      <>
        <div className={styles[`${componentName}__header`]}>
          <div className={styles[`${componentName}__header-container`]}>
            <button
              onClick={() => dispatch(setAITemplateModalState(false))}
              className={styles[`${componentName}__header-close-button`]}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                width={20}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>

            <h3>Custom Templates</h3>
          </div>
          <Button
            type="primary"
            onClick={() => setSelectedTab(TabType.CREATE)}
            icon={<PlusOutlined />}
            aria-label="Create Template"
          />
        </div>
        <Divider style={{ marginBlock: "0" }} />
      </>
    );
  }

  return (
    <>
      <Header />
      <List
        setSelectedTab={setSelectedTab}
        handleEditTemplate={handleEditTemplate}
        customNoteList={customNoteList}
        fetchCustomNoteList={fetchCustomNoteList}
        isListLoading={isListLoading}
      />
    </>
  );
};

export default ListContainer;
