import { Button, Form, Modal, Segmented, Select, Space, Steps, Typography } from "antd";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import YouTube, { YouTubePlayer } from "react-youtube";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { useIsMobileView } from "../../hooks/ui-hook";
import { patchUserInfo } from "../../service/auth.service";
import { UserTier, updateUserInfo } from "../../slices/authSlice";
import { EventType, trackEvent } from "../../utils/analytics";

const { Title, Text } = Typography;
const { Option } = Select;

interface OnboardingModalProps {
  visible: boolean;
}

enum OnboardingStep {
  QUESTIONS,
  TUTORIAL,
}

export const OnboardingModal: React.FC<OnboardingModalProps> = ({ visible }) => {
  const isMobile = useIsMobileView();
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState<OnboardingStep>(OnboardingStep.QUESTIONS);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state) => state.auth);
  const youtubeVideoRef = useRef<YouTubePlayer | null>(null);

  // Pre-fill form with existing data
  useEffect(() => {
    if (userInfo.onboarding_data) {
      form.setFieldsValue({
        profession: userInfo.onboarding_data.profession,
        practiceSize: userInfo.onboarding_data.practice_size,
        referralSource: userInfo.onboarding_data.referral_source,
      });
    }
  }, [userInfo.onboarding_data, form]);

  useEffect(() => {
    if (visible && !userInfo.onboarding_data?.completed) {
      trackEvent(EventType.ONBOARDING_STARTED, {
        profession: userInfo.onboarding_data?.profession,
        practice_size: userInfo.onboarding_data?.practice_size,
        referral_source: userInfo.onboarding_data?.referral_source,
      });
    }
  }, [visible, userInfo.onboarding_data]);

  const handleQuestionsSubmit = async (values: any) => {
    try {
      await patchUserInfo({
        onboarding_data: {
          ...userInfo.onboarding_data,
          profession: values.profession,
          practice_size: values.practiceSize,
          referral_source: values.referralSource,
          completed: false,
          start_time: undefined,
          reward_claimed: false,
        },
      });
      dispatch(updateUserInfo());
      setCurrentStep(OnboardingStep.TUTORIAL);
      trackEvent(EventType.ONBOARDING_QUESTIONS_SUBMITTED, {
        profession: values.profession,
        practice_size: values.practiceSize,
        referral_source: values.referralSource,
      });
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleVideoClose = async () => {
    if (youtubeVideoRef.current) {
      youtubeVideoRef.current?.internalPlayer.pauseVideo();
    }
    try {
      await patchUserInfo({
        onboarding_data: {
          ...userInfo.onboarding_data,
          completed: true,
          start_time: new Date().toISOString(),
        },
      });
      await dispatch(updateUserInfo()).unwrap();
      trackEvent(EventType.ONBOARDING_COMPLETED);
      navigate("/home", { replace: true });
    } catch (error) {
      console.error("Error completing onboarding:", error);
    }
  };

  const getModalWidth = useCallback(() => {
    if (isMobile) return "100%";
    return 800;
  }, [isMobile]);

  const steps = [
    {
      title: "Questions",
      content: (
        <Form
          form={form}
          layout="vertical"
          onFinish={handleQuestionsSubmit}
          style={{ width: "100%", maxWidth: "500px", margin: "0 auto", paddingTop: isMobile ? "16px" : "24px" }}
        >
          <Space direction="vertical" size="large" style={{ width: "100%", padding: isMobile ? "16px 0" : 0 }}>
            <Form.Item
              name="profession"
              label="What is your profession/specialization?"
              rules={[{ required: true, message: "Please select your profession" }]}
            >
              <Select placeholder="Select your profession" size={isMobile ? "large" : "middle"}>
                <Option value="pmhnp">Psychiatric Mental Health Nurse Practitioner (PMHNP)</Option>
                <Option value="psychiatrist">Psychiatrist</Option>
                <Option value="therapist">Therapist</Option>
                <Option value="counselor">Counselor</Option>
                <Option value="other">Other</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="practiceSize"
              label="How many providers are in your practice?"
              rules={[{ required: true, message: "Please select the number of providers" }]}
              initialValue="solo"
            >
              <Segmented
                block
                options={[
                  { label: "Only me", value: "solo" },
                  { label: "2-10", value: "2-10" },
                  { label: "11-50", value: "11-50" },
                  { label: "50+", value: "50+" },
                ]}
                style={{
                  width: "100%",
                  marginTop: "8px",
                }}
                size={isMobile ? "large" : "middle"}
                className="practice-size-selector"
              />
            </Form.Item>

            <Form.Item
              name="referralSource"
              label="How did you hear about us?"
              rules={[{ required: true, message: "Please tell us how you heard about us" }]}
            >
              <Select placeholder="Select referral source" size={isMobile ? "large" : "middle"}>
                <Option value="google">Google Search</Option>
                <Option value="facebook">Facebook</Option>
                <Option value="instagram">Instagram</Option>
                <Option value="colleague">Colleague Referral</Option>
                <Option value="conference">Conference/Event</Option>
                <Option value="other">Other</Option>
              </Select>
            </Form.Item>

            <Form.Item style={{ marginBottom: 0, display: "flex", justifyContent: "center" }}>
              <Button type="primary" htmlType="submit" size={isMobile ? "large" : "middle"}>
                Continue
              </Button>
            </Form.Item>
          </Space>
        </Form>
      ),
    },
    {
      title: "Tutorial",
      content: (
        <div
          style={{
            textAlign: "center",
            paddingTop: isMobile ? "16px" : "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Title style={{ marginTop: 0 }} level={4}>
            Our CEO's Message for You
          </Title>
          <Text
            style={{
              fontSize: isMobile ? "14px" : "16px",
              marginTop: 0,
            }}
          >
            See how Nudge saves time, helps you earn more, and adapts to your writing style.
          </Text>
          <div
            style={{
              width: "100%",
              maxWidth: isMobile ? "100%" : "640px",
              position: "relative",
              paddingBottom: isMobile ? "75%" : "56.25%", // Increased height ratio for mobile
              height: 0,
              overflow: "hidden",
              marginBottom: isMobile ? "16px" : "24px",
            }}
          >
            <YouTube
              ref={youtubeVideoRef}
              videoId="Yf4wOZV75fY"
              opts={{
                width: "100%",
                height: "100%",
                playerVars: {
                  autoplay: 0,
                },
              }}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </div>
          <Button
            type="primary"
            onClick={handleVideoClose}
            size={isMobile ? "large" : "middle"}
            style={{ width: isMobile ? "100%" : "auto" }}
          >
            Get Started
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Modal
      open={visible}
      closable={false}
      maskClosable={false}
      footer={null}
      width={getModalWidth()}
      centered
      style={{
        maxHeight: isMobile ? "90vh" : "90vh",
        margin: isMobile ? "5vh 0" : "24px",
        padding: isMobile ? 0 : "24px",
        overflow: "auto",
        top: isMobile ? 0 : 20,
      }}
      wrapClassName="onboarding-modal"
      styles={{
        body: {
          padding: isMobile ? 0 : "24px",
          height: isMobile ? "calc(90vh - 10vh)" : "auto",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Steps
        current={currentStep}
        items={steps.map((s) => ({
          title: isMobile ? s.title : s.title, // Show titles on both mobile and desktop
          description: isMobile ? "" : undefined,
        }))}
        style={{
          marginBottom: 0,
          padding: isMobile ? "0 16px" : 0,
        }}
        size={isMobile ? "small" : "default"}
      />
      <div style={{ flex: 1, overflow: "auto" }}>{steps[currentStep].content}</div>
    </Modal>
  );
};
