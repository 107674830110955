import { Button, Drawer, Flex, message } from "antd";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import { createCustomNote, getCustomNoteList, updateCustomNote } from "../../../service/notes.service";
import { setAITemplateModalState, setTemplateOpenedFromNoteModal } from "../../../slices/appStateSlice";
import { setRecordingDetail } from "../../../slices/recordingsSlice";
import Create from "./Create";
import ListContainer from "./ListContainer";
import { type CustomNote, TabType } from "./types";

export const AITemplateModal = () => {
  const dispatch = useAppDispatch();
  const { isAITemplateModalVisible } = useAppSelector((state) => state.appState);
  const [selectedTab, setSelectedTab] = React.useState<TabType>(TabType.LIST);
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = React.useState(true);
  const [currentTemplate, setCurrentTemplate] = React.useState<CustomNote | null>(null);
  const [isEditing, setIsEditing] = React.useState(false);
  const templateOpenedFromNoteModal = useAppSelector((state) => state.appState.templateOpenedFromNoteModal);
  const { recordingDetail } = useAppSelector((state) => state.recordings);
  const [isLoading, setIsLoading] = React.useState(false);
  const [customNoteList, setCustomNoteList] = React.useState<CustomNote[]>([]);
  const [pollingInterval, setPollingInterval] = React.useState<NodeJS.Timeout | null>(null);
  const [initialLoading, setInitialLoading] = React.useState(true);

  const fetchCustomNoteList = async (isPolling = false) => {
    try {
      const response = await getCustomNoteList();
      setCustomNoteList([...response.data]);
    } catch (error) {
      console.error("Error fetching templates:", error);
    } finally {
      if (!isPolling) {
        setInitialLoading(false);
      }
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const startPolling = React.useCallback(() => {
    if (pollingInterval) {
      clearInterval(pollingInterval);
    }

    if (selectedTab === TabType.LIST) {
      const interval = setInterval(() => {
        if (isAITemplateModalVisible) {
          console.log("Polling for updates...");
          fetchCustomNoteList(true);
        }
      }, 5000);

      setPollingInterval(interval);
    }
  }, [selectedTab, isAITemplateModalVisible]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  React.useEffect(() => {
    if (isAITemplateModalVisible && selectedTab === TabType.LIST) {
      setIsLoading(false);
      startPolling();
    } else if (pollingInterval) {
      clearInterval(pollingInterval);
      setPollingInterval(null);
    }
    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
        setPollingInterval(null);
      }
    };
  }, [isAITemplateModalVisible, selectedTab]);

  const handleDrawerClose = React.useCallback(() => {
    if (pollingInterval) {
      clearInterval(pollingInterval);
    }
    dispatch(setAITemplateModalState(false));
    setTimeout(() => {
      setSelectedTab(TabType.LIST);
      setCurrentTemplate(null);
      setIsEditing(false);
      setCustomNoteList([]);
      setPollingInterval(null);
    }, 300);
  }, [dispatch, pollingInterval]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  React.useEffect(() => {
    if (isAITemplateModalVisible) {
      setInitialLoading(true);
      setTimeout(() => {
        fetchCustomNoteList(false);
      }, 100);
    }
  }, [isAITemplateModalVisible]);

  function handleEditTemplate(template: CustomNote) {
    setIsEditing(true);
    setCurrentTemplate(template);
    setSelectedTab(TabType.CREATE);
  }

  async function handleGoBack() {
    setSelectedTab(TabType.LIST);
    setCurrentTemplate(null);
    setIsEditing(false);
    await fetchCustomNoteList();
  }

  const drawerFooter = {
    [TabType.CREATE]: (
      <Flex gap={16} justify="flex-end">
        <Button
          type="default"
          onClick={async () => {
            setSelectedTab(TabType.LIST);
            await fetchCustomNoteList();
            setCurrentTemplate(null);
          }}
        >
          Go back
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            try {
              setIsLoading(true);
              if (currentTemplate) {
                if (isEditing) {
                  const res = await updateCustomNote(currentTemplate.pk, {
                    name: currentTemplate.name,
                    document: currentTemplate.document,
                    settings: currentTemplate.settings,
                  });
                  if (res.data.status === 2) {
                    message.error("Error in updating template. Please try again.");
                  } else {
                    await fetchCustomNoteList();
                    message.success("Your template was successfully updated");
                  }
                } else {
                  const { name, document, settings } = currentTemplate;
                  message.info("Template is generating and will take some time to process");
                  const res = await createCustomNote({ name, document, settings });
                  if (res.data.status === 2) {
                    message.error("Error in creating template. Please try again.");
                  } else {
                    await fetchCustomNoteList();
                    message.success(`Your template ${name} was successfully created`);
                  }
                  if (templateOpenedFromNoteModal) {
                    dispatch(setTemplateOpenedFromNoteModal(false));
                    dispatch(setAITemplateModalState(false));
                    dispatch(
                      setRecordingDetail({
                        ...recordingDetail,
                        note_type: res.data.type_id,
                        note_type_info: {
                          id: res.data.type_id,
                          name: res.data.name,
                        },
                      }),
                    );
                  }
                }
                setSelectedTab(TabType.LIST);
                setCurrentTemplate(null);
              }
            } catch (err) {
              console.error(err);
              message.error(isEditing ? "Failed to update template" : "Failed to create template");
            } finally {
              setIsLoading(false);
            }
          }}
          loading={isLoading}
          disabled={isPrimaryButtonDisabled}
        >
          {isEditing ? "Save changes" : "Create Template"}
        </Button>
      </Flex>
    ),
    [TabType.LIST]:
      customNoteList.length === 0 ? null : (
        <Flex gap={16} justify="flex-end">
          <Button type="default" onClick={() => dispatch(setAITemplateModalState(false))}>
            Close
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setSelectedTab(TabType.CREATE);
            }}
          >
            Create Template
          </Button>
        </Flex>
      ),
  };

  return (
    <Drawer
      closable={false}
      open={isAITemplateModalVisible}
      onClose={handleDrawerClose}
      width={600}
      styles={{
        body: {
          padding: "0",
        },
      }}
      footer={drawerFooter[selectedTab]}
    >
      <>
        {selectedTab === TabType.LIST && (
          <ListContainer
            setSelectedTab={setSelectedTab}
            handleEditTemplate={handleEditTemplate}
            customNoteList={customNoteList}
            fetchCustomNoteList={fetchCustomNoteList}
            isListLoading={initialLoading}
          />
        )}
        {selectedTab === TabType.CREATE && (
          <Create
            setIsPrimaryButtonDisabled={setIsPrimaryButtonDisabled}
            setCurrentTemplate={setCurrentTemplate}
            isEditing={isEditing}
            currentTemplate={currentTemplate}
            handleGoBack={handleGoBack}
          />
        )}
      </>
    </Drawer>
  );
};
