import styles from "./notes.module.scss";
const NoteSectionTitle = ({
  title,
}: {
  title: string;
}) => {
  const componentName = "notes";
  return <span className={`${styles[`${componentName}__main-section-title`]}`}>{title}</span>;
};

export default NoteSectionTitle;
