// Utility to hold a reference to the wake lock
let wakeLock: any | null = null;

// Function to request the wake lock
export const requestWakeLock = async (): Promise<void> => {
  if (!("wakeLock" in navigator)) {
    console.error("Wake Lock API is not supported in this browser.");
    return;
  }

  try {
    wakeLock = await (navigator as any).wakeLock.request("screen");
    console.log("Screen Wake Lock is active");
  } catch (err) {
    // Assuming err is of type DOMException
    const error = err as DOMException;
    console.log(`Could not obtain wake lock: ${error.name}, ${error.message}`);
  }
};

// Function to release the wake lock
export const releaseWakeLock = async (): Promise<void> => {
  if (!wakeLock) {
    return;
  }
  try {
    await wakeLock.release();
    wakeLock = null;
    console.log("Screen Wake Lock has been released");
  } catch (err) {
    // Assuming err is of type DOMException
    const error = err as DOMException;
    console.error(`Could not release wake lock: ${error.name}, ${error.message}`);
  }
};
