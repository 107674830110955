import { AnalyticsBrowser } from "@segment/analytics-next";
import * as Sentry from "@sentry/browser";
import posthog from "posthog-js";
import { clarity } from "react-microsoft-clarity";
import { UAParser } from "ua-parser-js";

const clarityId = process.env.REACT_APP_CLARITY_ID || "";
const serverType = process.env.REACT_APP_SERVER_TYPE || "";
const segmentWriteKey = process.env.REACT_APP_SEGMENT_WRITE_KEY || "";

const GA_COOKIE_NAME = "_ga";
const GA_SESSION_COOKIE_NAME = "_ga_C05D7SN8JV";

const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey });
const isDev = serverType === "dev" || serverType === "dev2";
// Start seeing data on the Clarity dashboard with your id
export const initAnalytics = () => {
  if (isDev) {
    return;
  }
  clarity.init(clarityId);
};

export const identifyUser = (userEmail: string, userProperties: object = {}) => {
  if (isDev) {
    return;
  }
  // downcase email
  const formattedUserEmail = userEmail.toLowerCase();
  // identify user in Clarity and Segment
  clarity.identify(formattedUserEmail, userProperties);
  analytics.identify(formattedUserEmail, userProperties);
  Sentry.setUser({ email: formattedUserEmail });
  posthog.identify(formattedUserEmail, { email: formattedUserEmail });
};

// Helper function to get cookie value by name
const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift() || null;
  return null;
};

// Helper function to extract GA client ID from _ga cookie
export const extractGAClientId = (): string | null => {
  const gaCookie = getCookie(GA_COOKIE_NAME);
  if (!gaCookie) return null;
  const match = gaCookie.match(/GA\d\.\d\.(.+)/);
  return match ? match[1] : null;
};

// Helper function to extract GA session ID from _ga_C05D7SN8JV cookie
export const extractGASessionId = (): string | null => {
  const gaSessionCookie = getCookie(GA_SESSION_COOKIE_NAME);
  if (!gaSessionCookie) return null;
  const match = gaSessionCookie.match(/GS\d\.\d\.(\d+)\./);
  return match ? match[1] : null;
};

// make event properties optional with default empty object
export const trackEvent = (eventName: EventType, eventProperties: object = {}) => {
  if (isDev) {
    return;
  }

  // Get GA cookie values and extract the required parts
  const gaClientId = extractGAClientId();
  const gaSessionId = extractGASessionId();

  // Merge GA data with event properties
  const enrichedProperties = {
    ...eventProperties,
    ...(gaClientId && { ga_client_id: gaClientId }),
    ...(gaSessionId && { ga_session_id: gaSessionId }),
  };

  analytics.track(eventName, enrichedProperties);
};

export enum EventType {
  REGISTER = "register",
  LOGIN = "login",
  LOGOUT = "logout",
  FORGOT_PASSWORD = "forgot_password_button",
  RESET_PASSWORD = "reset_password",
  DELETE_NOTE = "delete_note",
  SUBSCRIBE_BUTTON = "subscription_modal",
  CHECKOUT_SUBSCRIPTION = "checkout_subscription",
  PURCHASE_COMPLETED = "purchase_completed",
  SELECT_NOTE = "select_note",
  RETRY_BUTTON = "retry_button",
  RESUME_BUTTON = "resume_button",
  STOP_BUTTON = "stop_button",
  START_BUTTON = "start_button",
  SUBMIT_AUDIO_MODAL = "submit_audio_button",
  FREE_TRIAL_LIMIT_MODAL = "free_trial_limit_modal",
  SUBMIT_AUDIO_FINAL = "submit_audio_final",
  UPLOAD_AUDIO_MODAL = "upload_audio_modal",
  UPLOAD_AUDIO_FINAL = "upload_audio_final",
  WRITE_AUDIO_MODAL = "write_audio_modal",
  WRITE_AUDIO_FINAL = "write_audio_final",
  COPY_NOTE = "copy_note",
  SAVE_NOTE = "save_note",
  GENERATE_CLIENT_EMAIL = "generate_client_email",
  COPY_CLIENT_EMAIL = "copy_client_email",
  CHANGE_MODALITY_MODE = "change_modality_mode",
  PROGRESS_TUTORIAL = "progress_tutorial",
  COMPLETE_TUTORIAL = "complete_tutorial",
  SKIP_TUTORIAL = "skip_tutorial",
  SHARE_MODAL_OPEN = "share_modal_open",
  SHARE_MODAL_BUTTON_CLICKED = "share_modal_button_clicked",
  GENERATE_SESSION_INSIGHTS = "generate_session_insights",
  EXPAND_SESSION_INSIGHTS = "expand_session_insights",
  CONTACT_US = "contact_us",
  GENERATE_SESSION_PLAN = "generate_session_plan",
  EXPAND_SESSION_PLAN = "expand_session_plan",
  GENERATE_TRANSCRIPT = "generate_transcript",

  CLICKED_NEXT_STEP_TUTORIAL = "clicked_next_step_tutorial",
  CLICKED_PREVIOUS_STEP_TUTORIAL = "clicked_previous_step_tutorial",
  CLICKED_ON_CAPTURE_DEMO_PATH = "clicked_on_capture_demo_path",
  CLICKED_ON_DESCRIPTION_DEMO_PATH = "clicked_on_description_demo_path",
  CLICKED_ON_DICTATE_DEMO_PATH = "clicked_on_dictate_demo_path",
  CLICKED_ON_UPLOAD_DEMO_PATH = "clicked_on_upload_demo_path",

  CLICKED_ON_SUBMIT_NOTE_BEFORE_SIGNUP = "clicked_on_submit_note_before_signup",
  CLICKED_TRY_PRO_FREE = "clicked_try_pro_free",

  CLICKED_ON_FULL_NAME_DEMO_PATH = "clicked_on_full_name_demo_path",
  CLICKED_ON_EMAIL_DEMO_PATH = "clicked_on_email_demo_path",
  CLICKED_ON_PASSWORD_DEMO_PATH = "clicked_on_password_demo_path",

  CLICKED_PROGRESS_SUMMARY_SHOW_MORE = "clicked_progress_summary_show_more",
  CLICKED_PROGRESS_SUMMARY_SHOW_LESS = "clicked_progress_summary_show_less",

  GOOGLE_SIGN_UP = "google_sign_up",
  GOOGLE_SIGN_IN = "google_sign_in",

  // Onboarding flow events
  ONBOARDING_STARTED = "onboarding_started",
  ONBOARDING_QUESTIONS_SUBMITTED = "onboarding_questions_submitted",
  ONBOARDING_TUTORIAL_COMPLETED = "onboarding_tutorial_completed",
  ONBOARDING_COMPLETED = "onboarding_completed",
  ONBOARDING_REWARD_TIMER_STARTED = "onboarding_reward_timer_started",
  ONBOARDING_REWARD_CLAIMED = "onboarding_reward_claimed",
  ONBOARDING_REWARD_EXPIRED = "onboarding_reward_expired",
  ONBOARDING_FIRST_NOTE_STARTED = "onboarding_first_note_started",
  ONBOARDING_FIRST_NOTE_COMPLETED = "onboarding_first_note_completed",
}

export enum ShareModal {
  COPY_LINK = "copy_link",
  GMAIL = "gmail",
  YAHOO = "yahoo",
  OUTLOOK = "outlook",
  SHARE_BUTTON = "share_button",
}

export enum NoteSection {
  ALL = "all",
  SUBJECTIVE = "subjective",
  OBJECTIVE = "objective",
  ASSESSMENT = "assessment",
  PLAN = "plan",
  DATA = "data",
  NUDGE_INSIGHTS = "nudge_insights",
  TREATMENT_PLAN = "treatment_plan",
  SESSION_DETAILS = "session_details",
  INTAKE_NOTES = "intake_notes",
  EAP_INTAKE_NOTES = "eap_intake_notes",
  EMDR_NOTES = "emdr_notes",
  PSYCH_INTAKE_NOTES = "psych_intake_notes",
  SOL_THERAPY_FOLLOW_UP = "sol_therapy_follow_up",
  SOL_THERAPY_INTAKE_NOTE = "sol_therapy_intake_note",
  SOL_PSYCH_INTAKE_NOTE = "sol_psych_intake_note",
  PSYCH_FOLLOW_UP = "psych_follow_up",
  SOAP_SPEECH_THERAPY = "document",
  CONSULT_NOTE = "document",
  CUSTOM_NOTE = "document",
}

export const getSystemMetadata = () => {
  const ua = navigator.userAgent;

  const parser = new UAParser(ua);

  return {
    browser: parser.getBrowser(),
    os: parser.getOS(),
    device: parser.getDevice() || "unknown",
  };
};
