export const DEFAULT_NOTE_TYPES = [
  {
    name: "SOAP",
    type: "SOAP",
  },
  {
    name: "DAP",
    type: "DAP",
  },
  {
    name: "Therapy Intake",
    type: "Intake",
  },
  {
    name: "EMDR",
    type: "EMDR",
  },
  {
    name: "Psychiatric Intake",
    type: "PSYCHIATRIC_INTAKE",
  },
  {
    name: "Psychiatric Follow-up (SOAP)",
    type: "PSYCHIATRIC_FOLLOW_UP_SOAP",
  },

  {
    name: "EAP Intake",
    type: "EAP_INTAKE",
  },
  {
    name: "SOAP (Speech Therapy)",
    type: "SOAP_SPEECH_THERAPY",
  },
  {
    name: "Consult Note",
    type: "CONSULT_NOTE",
  },
];

export const SOL_NOTE_TYPES = [
  {
    name: "Psychiatric Intake",
    type: "SOL_PSYCH_INTAKE",
  },
  {
    name: "Psychiatric Follow-up (SOAP)",
    type: "PSYCHIATRIC_FOLLOW_UP",
  },
  {
    name: "Therapy Intake",
    type: "THERAPY_INTAKE",
  },
  {
    name: "Therapy Follow-up (SOAP)",
    type: "THERAPY_FOLLOW_UP_SOAP",
  },
  {
    name: "Therapy Follow-up (DAP)",
    type: "THERAPY_FOLLOW_UP_DAP",
  },
  {
    name: "EMDR",
    type: "EMDR",
  },
  {
    name: "Consult Note",
    type: "CONSULT_NOTE",
  },
];

export const LLT_NOTE_TYPES = [
  {
    name: "SOAP",
    type: "SOAP",
  },
  {
    name: "SOAP (Speech Therapy)",
    type: "SOAP_SPEECH_THERAPY",
  },
];

export const GWUL_NOTE_TYPES = [
  {
    name: "SOAP",
    type: "SOAP",
  },
];
