import axiosInstance from "../api/axiosInstance";

type TPasswordResetBody = {
  new_password1: string;
  new_password2: string;
};

interface OnboardingData {
  profession?: string;
  practice_size?: number;
  referral_source?: string;
  completed?: boolean;
  start_time?: string;
  reward_claimed?: boolean;
}

interface UtmData {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

interface UpdateUserData {
  onboarding_data?: OnboardingData;
  utm_data?: UtmData;
}

export const handleChangePassword = (passwordResetBody: TPasswordResetBody) => {
  return axiosInstance.post(`/rest-auth/password/change/`, passwordResetBody);
};

export const getUserData = () => {
  return axiosInstance.get(`/users/user`);
};

export const getUserInfo = () => {
  return axiosInstance.get(`/users/userinfo/`);
};

export const patchUserInfo = async (data: UpdateUserData) => {
  const response = await axiosInstance.patch("/users/userinfo/", data);
  return response.data;
};
