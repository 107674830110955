import { Button, Form, Input, Modal, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changePassword, resetBasicUserInfo } from "../../slices/authSlice";
import { RootState } from "../../store";

type Props = {
  show: boolean;
  onClose: () => void;
};

export default function ChangePassword({ show, onClose }: Props) {
  const dispatch: any = useDispatch();

  const user = useSelector((state: RootState) => state.user);

  const onFinish = async ({
    newPassword,
    confirmPassword,
  }: {
    newPassword: string;
    confirmPassword: string;
  }) => {
    if (newPassword !== confirmPassword) {
      message.error("Passwords do not match");
      return;
    }
    try {
      const passwordChanged = await dispatch(
        changePassword({
          email: user.data?.email,
          newPassword: newPassword,
        }),
      ).unwrap();

      if (passwordChanged) {
        message.success("Password changed successfully");
        onClose();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal title="Change Password" open={show} onCancel={onClose} footer={null}>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600, padding: "20px" }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            { required: true, message: "Please input New Password password!" },
            {
              min: 5,
              message: "Password must be at least 5 characters long",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          rules={[
            { required: true, message: "Please input Confirm password!" },
            {
              min: 5,
              message: "Password must be at least 5 characters long",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          style={{
            textAlign: "right",
          }}
        >
          <Button type="primary" htmlType="submit">
            Change Password
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
