export type tabs = "list" | "create";

export enum TabType {
  LIST = "list",
  CREATE = "create",
}

export type CustomNote = {
  id: number;
  pk: number;
  name: string;
  document: string;
  finished_generating: boolean;
  settings: {
    is_addon: boolean;
    is_cpt_codes: boolean;
  };
  status: 1 | 0 | 2;
};
