import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { analyticsApi } from "./service/analytics.service";
import appStateSlice from "./slices/appStateSlice";
import authReducer from "./slices/authSlice";
import clientSlice from "./slices/clientSlice";
import notesSlice from "./slices/notesSlice";
import recodingsReducer from "./slices/recordingsSlice";
import userReducer from "./slices/userSlice";

const store = configureStore({
  reducer: {
    [analyticsApi.reducerPath]: analyticsApi.reducer,
    auth: authReducer,
    user: userReducer,
    recordings: recodingsReducer,
    appState: appStateSlice,
    notes: notesSlice,
    client: clientSlice,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["recordings/setMediaRecorder"],
        ignoredPaths: ["recordings.mediaRecorder"],
      },
    }).concat(analyticsApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
