import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Flex, Select } from "antd";
import React from "react";
import { NoteType } from "../../domain/notes.domain";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { useIsMobileView } from "../../hooks/ui-hook";
import { getCustomNoteList } from "../../service/notes.service";
import { setAITemplateModalState, setTemplateOpenedFromNoteModal } from "../../slices/appStateSlice";
import { setRecordingDetail } from "../../slices/recordingsSlice";
import { CustomNote } from "./AITemplateModal/types";
import styles from "./createnotemodal.module.scss";

const NoteTypeSelector = ({ noteTypes }: { noteTypes: { name: string; type: string }[] }) => {
  const componentName = "createnotemodal";
  const dispatch = useAppDispatch();
  const isMobile = useIsMobileView();
  const { recordingDetail } = useAppSelector((state) => state.recordings);
  const [customNoteList, setCustomNoteList] = React.useState<any[]>([]);
  const { templateOpenedFromNoteModal } = useAppSelector((state) => state.appState);

  const fetchCustomNoteList = async () => {
    const response = await getCustomNoteList();
    let data = await response.data;
    data = data.filter((item: CustomNote) => item.status === 0);
    setCustomNoteList(data);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: need to fetch custom note list when modal is opened
  React.useEffect(() => {
    fetchCustomNoteList();
  }, [templateOpenedFromNoteModal]);

  const allNoteTypes = [
    ...(customNoteList.length > 0
      ? [
          {
            label: "Custom Templates",
            title: "Custom Templates",
            options: customNoteList.map((note: any) => ({
              label: note.name,
              value: note.id,
            })),
          },
        ]
      : []),
    {
      label: "Nudge Templates",
      title: "Nudge Templates",
      options: noteTypes.map((note: any) => ({
        label: note.name,
        value: NoteType[note.type],
      })),
    },
  ];

  function handleAddTemplate() {
    if (isMobile) {
      alert("This feature is available on Desktop");
    } else {
      dispatch(setAITemplateModalState(true));
      dispatch(setTemplateOpenedFromNoteModal(true));
    }
  }

  return (
    <Col span={24} className={`${styles[`${componentName}__modal-row`]}`}>
      <Select
        value={recordingDetail?.note_type}
        listHeight={isMobile ? 160 : 256}
        onChange={(value) => {
          dispatch(
            setRecordingDetail({
              ...recordingDetail,
              note_type: value,
              modality_type: value === NoteType.CONSULT_NOTE ? "write" : recordingDetail.modality_type,
            }),
          );
        }}
        showSearch
        placeholder="Select a note type"
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label.toLowerCase() ?? "").includes(input?.toLowerCase())}
        dropdownRender={(menu) => (
          <div className="client">
            {menu}
            <Divider style={{ margin: "4px 0" }} />
            <Flex gap={10}>
              <Button type="text" icon={<PlusOutlined />} onClick={handleAddTemplate}>
                Add Custom Template
              </Button>
            </Flex>
          </div>
        )}
        className={`${styles[`${componentName}__modal-row-field`]}`}
        options={allNoteTypes}
      />
    </Col>
  );
};

export default NoteTypeSelector;
