import type { Key } from "react";
import axiosInstance from "../api/axiosInstance";

export interface Member {
  id: number;
  email: string;
  name: string;
  role: "admin" | "member";
}

export interface Invitation {
  id: number;
  email: string;
  sent_at: string;
  inviter_name: string;
}

export interface PaginatedResponse<T> {
  count: number;
  results: T[];
  billing_count?: number;
}

export interface InviteResponse {
  invitations: Invitation[];
  has_errors: boolean;
  existing_invites?: string[];
  existing_members?: string[];
}

export const membersService = {
  getMembers: async (page = 1, search?: string): Promise<PaginatedResponse<Member>> => {
    const response = await axiosInstance.get<PaginatedResponse<Member>>("/users/group/members/", {
      params: {
        page,
        search: search || undefined, // only include if search has a value
      },
    });
    return response.data;
  },

  getInvitations: async (page = 1): Promise<PaginatedResponse<Invitation>> => {
    const response = await axiosInstance.get<PaginatedResponse<Invitation>>("/users/group/invitations/", {
      params: { page },
    });
    return response.data;
  },

  inviteMembers: async (emails: string[]): Promise<InviteResponse> => {
    const response = await axiosInstance.post<InviteResponse>("/users/group/invite/", { emails });
    return response.data;
  },

  resendInvitation: async (invitationId: number): Promise<void> => {
    await axiosInstance.post(`/users/group/invitations/${invitationId}/`);
  },

  revokeInvitation: async (invitationId: number): Promise<void> => {
    await axiosInstance.delete(`/users/group/invitations/${invitationId}/`);
  },

  removeMember: async (memberId: number): Promise<void> => {
    await axiosInstance.delete(`/users/group/members/${memberId}/`);
  },

  removeMembersBatch: async (memberIds: Key[]): Promise<void> => {
    await Promise.all(memberIds.map((memberId) => axiosInstance.delete(`/users/group/members/${memberId}/`)));
  },

  revokeInvitationsBatch: async (invitationIds: Key[]): Promise<void> => {
    await Promise.all(
      invitationIds.map((invitationId) => axiosInstance.delete(`/users/group/invitations/${invitationId}/`)),
    );
  },

  promoteToAdmin: async (memberId: number): Promise<void> => {
    await axiosInstance.post(`/users/group/members/${memberId}/promote/`);
  },
};
