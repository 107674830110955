import { Flex, Typography } from "antd";
import { TProgressSummary } from "../../types/index.type";
import PreWrapText from "../PreWrapText/PreWrapText.component";
import styles from "./progresssummary.module.scss";

const ProgressSummaryView = ({
  summary,
}: {
  summary: TProgressSummary | null;
}) => {
  const componentName = "progresssummary";
  if (!summary) {
    return <div>No Summary Available</div>;
  }
  return (
    <Flex vertical>
      <div>
        <Typography className={styles[`${componentName}-header`]}>Primary Concern</Typography>
      </div>
      <div>
        <PreWrapText text={summary.primary_concern} />
      </div>
      <div>
        <Typography className={styles[`${componentName}-header`]}>Next Steps</Typography>
        <PreWrapText text={summary.next_steps} />
      </div>
      <div>
        <Typography className={styles[`${componentName}-header`]}>Session Summary</Typography>
        <PreWrapText text={summary.session_summary} />
      </div>
    </Flex>
  );
};

export default ProgressSummaryView;
