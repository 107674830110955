import axiosInstance from "../api/axiosInstance";
import type { TNotes } from "../types/index.type";

type TUpdateBody = {
  audio_id: number;
  error_status: string;
};

type TWriteNoteBody = {
  audio_id?: number;
  note_text?: string;
  title: string;
  note_type: string | number;
  category_type: string | number;
  modality_mode: string;
  gender_type: string | number;
  language_type?: number;
  location_type?: number;
  duration_type?: number;
  client_id?: number;
  customization?: {
    customization_type: 0 | 1 | 2;
    note_type_instructions: {
      [key: number]:
        | string
        | {
            note: string;
            is_system: boolean;
          };
    };
    is_system: boolean;
    note_section: string;
    detail_level: number;
    formatting: number;
  };
  is_capture?: boolean;
  system_metadata?: { [key: string]: any };
  duration_addon_type?: number;
  is_locked?: boolean;
};

type TCancelRecordingBody = {
  audio_id: string;
  status: string;
};

export const setTimeOutReached = (body: TUpdateBody) => {
  return axiosInstance.put(`/notes/update/`, body);
};

export const handleDelete = (recordingId: number) => {
  return axiosInstance.delete(`/notes/delete/${recordingId}`);
};

export const handleCaptureAudioSubmission = (formData: FormData) => {
  return axiosInstance.post(`/notes/capture_audio/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const handleUploadAudioSubmission = (formData: FormData) => {
  return axiosInstance.post("/notes/upload_audio/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const handleWriteNoteSubmission = (body: TWriteNoteBody) => {
  return axiosInstance.post(`/notes/write_note/`, body);
};

export const handleSubmitNoteForProcessing = (body: Partial<TWriteNoteBody>) => {
  return axiosInstance.post("/notes/process_recording/", body);
};

export const handleCancelRecording = (body: TCancelRecordingBody) => {
  return axiosInstance.put("/notes/update/", body);
};

export const updateNote = (body: Partial<TNotes>) => {
  return axiosInstance.put("/notes/update/", body);
};

export const generateSessionInsight = (recordingId: number | undefined) => {
  return axiosInstance.post(`/notes/generate/session_insights/`, {
    audio_id: recordingId,
  });
};

export const handleGenerateTreatmentPlan = (recordingId: number | undefined) => {
  return axiosInstance.post(`/notes/generate/treatment_plan/`, {
    audio_id: recordingId,
  });
};

export const handleAVSEmailGenerate = (recordingId: number | undefined) => {
  return axiosInstance.post(`/notes/gen_avs_email/`, { audio_id: recordingId });
};

export const handleGenerateTranscriptOfNote = (recordingId: number | undefined) => {
  return axiosInstance.post(`/notes/gen_transcript/`, {
    audio_id: recordingId,
  });
};

export const completeTutorial = () => {
  return axiosInstance.post(`/users/complete_tutorial/`, {});
};

export const getNotesDetails = (recordingId: number | string | undefined) => {
  return axiosInstance.get(`/notes/fetch/${recordingId}/`);
};

export const getNotesList = (pageNumber?: number, signal?: AbortSignal) => {
  return axiosInstance.get(`/notes/list/?page=${pageNumber || 1}`, { signal });
};

export const getNotesListByClient = (clientId: number, pageNumber?: number) => {
  if (pageNumber) {
    return axiosInstance.get(`/notes/client/${clientId}/?page=${pageNumber}`);
  }
  return axiosInstance.get(`/notes/client/${clientId}/`);
};

export const getNotesListByClientUUID = (clientUuid: string, pageNumber?: number, signal?: AbortSignal) => {
  return axiosInstance.get(`/notes/client/uuid/${clientUuid}/?page=${pageNumber || 1}`, { signal });
};

export const getCustomizationList = () => {
  return axiosInstance.get(`notes/customization/list/`);
};

export const getCustomNote = (noteId: number) => {
  return axiosInstance.get(`/notes/custom_notes/${noteId}/`);
};

export const createCustomNote = (body: {
  name: string;
  document: string;
  settings: { is_addon: boolean; is_cpt_codes: boolean };
}) => {
  return axiosInstance.post(`/notes/custom_notes/`, body);
};

export const updateCustomNote = (
  noteId: number,
  body: { name: string; document: string; settings: { is_addon: boolean; is_cpt_codes: boolean } },
) => {
  return axiosInstance.put(`/notes/custom_notes/${noteId}/`, body);
};

export const deleteCustomNote = (noteId: number) => {
  return axiosInstance.delete(`/notes/custom_notes/${noteId}/`);
};

export const getCustomNoteList = () => {
  return axiosInstance.get(`/notes/custom_notes/available_note_types/`);
};
