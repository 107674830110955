import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export type AnalyticsObject = {
  id: number;
  email: string;
  name: string;
  joined_on: string;
  last_note_created_on: string;
  total_number_of_notes_created: number;
  number_of_intakes_created_per_month: number;
  number_of_clients_created_per_month: number;
  number_of_ongoing_clients: number;
  average_time_per_session: null | number;
};

export type DiagnosesBreakdown = {
  name: string;
  value: number;
};

type ListResponse<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
};

export const analyticsApi = createApi({
  reducerPath: "analyticsApi",
  tagTypes: ["Analytics"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Token ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getUserAnalytics: build.query<ListResponse<AnalyticsObject>, { page?: number; search?: string }>({
      query: ({ page, search }: { page?: number; search?: string } = {}) =>
        `/analytics/therapist_table/?${search ? `search=${encodeURIComponent(search)}&` : ""}page=${page || ""}`,

      // This is to invalidate the paginated query in case of any mutation (advanced use case)
      // https://redux-toolkit.js.org/rtk-query/usage/pagination#automated-re-fetching-of-paginated-queries
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ id }) => ({
                type: "Analytics" as const,
                id,
              })),
              { type: "Analytics", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Analytics", id: "PARTIAL-LIST" }],
    }),
    // more queries can be written for post, put, and delete methods if required
    getDiagnosesBreakdown: build.query<DiagnosesBreakdown[], void>({
      query: () => `/analytics/diagnoses_breakdown/`,
    }),
  }),
});

export const { useGetUserAnalyticsQuery, useGetDiagnosesBreakdownQuery } = analyticsApi;
