import { LoadingOutlined } from "@ant-design/icons";
import { Card, Flex, Skeleton } from "antd";
import React from "react";
import { getNotesType } from "../../domain/notes.domain";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { handleAVSEmailGenerate } from "../../service/notes.service";
import { setEmailContent } from "../../slices/notesSlice";
import { EventType, trackEvent } from "../../utils/analytics";
import styles from "./notes.module.scss";

const EmailView = () => {
  const dispatch = useAppDispatch();
  const componentName = "notes";
  const [generatingEmail, setGeneratingEmail] = React.useState<boolean>(true);

  const { isDemoPath } = useAppSelector((state) => state.appState);

  const { emailContent, notesDetails } = useAppSelector((state) => state.notes);

  const handleGenerateAvs = async () => {
    if (notesDetails) {
      trackEvent(EventType.GENERATE_CLIENT_EMAIL, {
        recordingId: notesDetails.id,
        noteType: getNotesType(notesDetails.note_type),
      });
      if (emailContent) {
        return;
      }
      handleAVSEmailGenerate(notesDetails.id)
        .then((result) => {
          dispatch(setEmailContent(result.data.email));
        })
        .catch((error) => {
          console.error(error);
          setGeneratingEmail(false);
        })
        .finally(() => {
          setGeneratingEmail(false);
        });
    }
  };

  // biome-ignore lint: no need to add emailContent to the dependency array
  React.useEffect(() => {
    if (isDemoPath && notesDetails) {
      dispatch(setEmailContent(notesDetails?.avs_email));
      setGeneratingEmail(false);
    } else if (!emailContent) {
      handleGenerateAvs();
    } else {
      setGeneratingEmail(false);
    }
  }, [notesDetails]);

  return (
    <Flex vertical gap={20}>
      <Flex vertical>
        {generatingEmail ? (
          <>
            <Flex>
              <LoadingOutlined /> &nbsp; <span>Generating email content</span>
            </Flex>
            <Skeleton active />
          </>
        ) : (
          <Card
            hoverable
            className={`${styles[`${componentName}__main-section`]}`}
            style={{
              textAlign: "left",
            }}
          >
            {emailContent ? (
              <div
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                {emailContent}
              </div>
            ) : (
              "No email content found."
            )}
          </Card>
        )}
      </Flex>
    </Flex>
  );
};

export default EmailView;
