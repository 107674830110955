import { DeleteOutlined, MoreOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Dropdown, Flex, Form, Input, Row, Select, Table, Tabs, Typography, message } from "antd";
import type { TabsProps } from "antd";
import type { MenuProps } from "antd";
import type { ColumnsType } from "antd/es/table";
import type React from "react";
import { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import Swal from "sweetalert2";
import { useAppSelector } from "../../hooks/redux-hooks";
import { useIsMobileView } from "../../hooks/ui-hook";
import useIntercomWithData from "../../hooks/useIntercomWithData";
import { type Invitation, type Member, membersService } from "../../service/members.service";
import styles from "./members.module.scss";

const { Text } = Typography;

const Members: React.FC = () => {
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState("1");
  const [members, setMembers] = useState<Member[]>([]);
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [loading, setLoading] = useState(false);
  const [inviteDrawerVisible, setInviteDrawerVisible] = useState(false);
  const [inviting, setInviting] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [membersTotal, setMembersTotal] = useState(0);
  const [invitationsTotal, setInvitationsTotal] = useState(0);
  const [selectedMembers, setSelectedMembers] = useState<React.Key[]>([]);
  const [selectedInvitations, setSelectedInvitations] = useState<React.Key[]>([]);
  const [totalBilledUsers, setTotalBilledUsers] = useState(0);
  const isMobileView = useIsMobileView();
  const { bootWithData, shutdown } = useIntercomWithData();
  const userInfo = useAppSelector((state) => state.auth.userInfo);

  useDebounce(
    () => {
      if (activeTab === "1") {
        fetchMembers(1, searchText);
      } else {
        fetchInvitations(1);
      }
    },
    300,
    [searchText, activeTab],
  );

  const fetchMembers = async (page = 1, search?: string) => {
    try {
      setLoading(true);
      const response = await membersService.getMembers(page, search);
      setMembers(response.results);

      if (!search) {
        setMembersTotal(response.count);

        if (response.billing_count !== undefined) {
          setTotalBilledUsers(response.billing_count);
        }
      }
    } catch {
      message.error("Failed to fetch members");
    } finally {
      setLoading(false);
    }
  };

  const fetchInvitations = async (page = 1) => {
    try {
      setLoading(true);
      const response = await membersService.getInvitations(page);
      setInvitations(response.results);

      if (page === 1) {
        setInvitationsTotal(response.count);
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to fetch invitations");
    } finally {
      setLoading(false);
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchMembers();
    fetchInvitations();
  }, []);

  useEffect(() => {
    if (inviteDrawerVisible) {
      shutdown();
    } else {
      if (!userInfo.is_paying || userInfo.group_name) {
        bootWithData();
      }
    }
  }, [inviteDrawerVisible, shutdown, bootWithData, userInfo.is_paying, userInfo.group_name]);

  const handleInvite = async () => {
    try {
      setInviting(true);
      const values = await form.validateFields();
      const emails = values.emails || [];

      if (emails.length === 0) {
        message.error("Please enter at least one valid email address");
        return;
      }

      const response = await membersService.inviteMembers(emails);

      if (response.invitations.length > 0) {
        message.success("Invitations sent successfully");
      }

      if (response.has_errors) {
        const existingInvites = response.existing_invites || [];
        const existingMembers = response.existing_members || [];

        if (existingInvites.length > 0) {
          message.warning(
            existingInvites.length === 1
              ? `An invitation has already been sent to ${existingInvites[0]}`
              : `Invitations have already been sent to: ${existingInvites.join(", ")}`,
          );
        }

        if (existingMembers.length > 0) {
          message.warning(
            existingMembers.length === 1
              ? `${existingMembers[0]} is already a member of the team`
              : `The following emails are already team members: ${existingMembers.join(", ")}`,
          );
        }
      }

      if (response.invitations.length > 0) {
        setInviteDrawerVisible(false);
        form.resetFields();
        fetchInvitations();
        fetchMembers();
        setActiveTab("2");
      }
    } catch (error: any) {
      if (error?.errorFields) {
        message.error("Please enter valid email addresses");
      } else {
        message.error("Failed to send invitations");
      }
    } finally {
      setInviting(false);
    }
  };

  const handleResendInvite = async (invitationId: number) => {
    try {
      const result = await Swal.fire({
        title: "Resend Invitation?",
        text: "Are you sure you want to resend this invitation?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes, resend it",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#6366F1",
      });

      if (result.isConfirmed) {
        await membersService.resendInvitation(invitationId);
        message.success("Invitation resent successfully");
        fetchInvitations();
      }
    } catch {
      message.error("Failed to resend invitation");
    }
  };

  const handleRevokeInvite = async (invitationId: number) => {
    try {
      const result = await Swal.fire({
        title: "Revoke Invitation?",
        text: "Are you sure you want to revoke this invitation?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, revoke it",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#6366F1",
      });

      if (result.isConfirmed) {
        await membersService.revokeInvitation(invitationId);
        message.success("Invitation revoked successfully");
        fetchInvitations();
        fetchMembers();
      }
    } catch {
      message.error("Failed to revoke invitation");
    }
  };

  const handleRemoveMember = async (memberId: number) => {
    try {
      const result = await Swal.fire({
        title: "Remove Member?",
        text: "Are you sure you want to remove this member from the group?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, remove",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#6366F1",
      });

      if (result.isConfirmed) {
        await membersService.removeMember(memberId);
        message.success("Member removed successfully");
        fetchMembers();
      }
    } catch {
      message.error("Failed to remove member");
    }
  };

  const handleBatchRemove = async () => {
    try {
      const result = await Swal.fire({
        title: "Remove Selected Members?",
        text: `Are you sure you want to remove ${selectedMembers.length} member(s)?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, remove them",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#6366F1",
      });

      if (result.isConfirmed) {
        await membersService.removeMembersBatch(selectedMembers);
        message.success("Members removed successfully");
        fetchMembers();
        setSelectedMembers([]);
      }
    } catch {
      message.error("Failed to remove members");
    }
  };

  const handleBatchRevokeInvites = async () => {
    try {
      const result = await Swal.fire({
        title: "Revoke Selected Invitations?",
        text: `Are you sure you want to revoke ${selectedInvitations.length} invitation(s)?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, revoke them",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#6366F1",
      });

      if (result.isConfirmed) {
        await membersService.revokeInvitationsBatch(selectedInvitations);
        message.success("Invitations revoked successfully");
        fetchInvitations();
        setSelectedInvitations([]);
      }
    } catch {
      message.error("Failed to revoke invitations");
    }
  };

  const handlePromoteToAdmin = async (memberId: number) => {
    try {
      const result = await Swal.fire({
        title: "Promote to Admin?",
        text: "Are you sure you want to promote this member to admin? They will have full access to manage the team.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, promote",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#6366F1",
      });

      if (result.isConfirmed) {
        await membersService.promoteToAdmin(memberId);
        message.success("Member promoted to admin successfully");
        fetchMembers();
      }
    } catch {
      message.error("Failed to promote member");
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedMembers,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedMembers(selectedRowKeys);
    },
  };

  const invitationRowSelection = {
    selectedRowKeys: selectedInvitations,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedInvitations(selectedRowKeys);
    },
  };

  const memberColumns: ColumnsType<Member> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role: string) => <span style={{ textTransform: "capitalize" }}>{role}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        if (record.role === "admin") return null;

        const items: MenuProps["items"] = [
          {
            key: "1",
            label: "Promote to Admin",
            onClick: () => handlePromoteToAdmin(record.id),
          },
          {
            type: "divider",
          },
          {
            key: "2",
            label: "Remove Member",
            danger: true,
            onClick: () => handleRemoveMember(record.id),
          },
        ];

        return (
          <Dropdown menu={{ items }} trigger={["click"]} placement="bottomRight">
            <Button type="text" icon={<MoreOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  const invitationColumns: ColumnsType<Invitation> = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      filteredValue: [searchText],
      onFilter: (value, record) => record.email.toLowerCase().includes(value.toString().toLowerCase()),
    },
    {
      title: "Sent By",
      dataIndex: "inviter_name",
      key: "inviter_name",
    },
    {
      title: "Sent At",
      dataIndex: "sent_at",
      key: "sent_at",
      render: (date: string) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        const items: MenuProps["items"] = [
          {
            key: "1",
            label: "Resend Invitation",
            onClick: () => handleResendInvite(record.id),
          },
          {
            key: "2",
            label: "Revoke Invitation",
            danger: true,
            onClick: () => handleRevokeInvite(record.id),
          },
        ];

        return (
          <Dropdown menu={{ items }} trigger={["click"]} placement="bottomRight">
            <Button type="text" icon={<MoreOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  const handleTableChange = (pagination: any) => {
    if (activeTab === "1") {
      fetchMembers(pagination.current);
    } else {
      fetchInvitations(pagination.current);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Members",
      children: (
        <Table
          rowSelection={rowSelection}
          columns={memberColumns}
          dataSource={members}
          loading={loading}
          pagination={{
            total: membersTotal,
            pageSize: 20,
            showSizeChanger: false,
          }}
          onChange={handleTableChange}
          rowKey="id"
          className={styles.members__table}
        />
      ),
    },
    {
      key: "2",
      label: "Invitations",
      children: (
        <Table
          rowSelection={invitationRowSelection}
          columns={invitationColumns}
          dataSource={invitations}
          loading={loading}
          rowKey="id"
          className={styles.members__table}
          onChange={handleTableChange}
          pagination={{
            total: invitationsTotal,
            pageSize: 20,
            showSizeChanger: false,
          }}
        />
      ),
    },
  ];

  return (
    <Row>
      <Col span={24} className={styles.members__container}>
        <Flex
          vertical={isMobileView}
          justify="space-between"
          align={isMobileView ? "stretch" : "center"}
          className={styles.members__container_header}
          gap={isMobileView ? 16 : 0}
        >
          <Flex vertical gap={8}>
            <Typography className={styles.members__container_title}>Manage Team</Typography>
          </Flex>
          <Flex vertical={isMobileView} align={isMobileView ? "stretch" : "center"} gap={12}>
            <Input
              placeholder="Search for members"
              onChange={(e) => setSearchText(e.target.value)}
              prefix={<SearchOutlined />}
              size="small"
              style={{
                padding: "4px 12px",
                borderRadius: 6,
                width: isMobileView ? "100%" : 200,
              }}
            />
            <Flex gap={8} style={{ width: isMobileView ? "100%" : "auto" }}>
              {activeTab === "1" && selectedMembers.length > 0 && (
                <Button
                  danger
                  onClick={handleBatchRemove}
                  icon={<DeleteOutlined />}
                  style={{
                    width: isMobileView ? "100%" : "auto",
                  }}
                >
                  Remove Selected ({selectedMembers.length})
                </Button>
              )}
              {activeTab === "2" && selectedInvitations.length > 0 && (
                <Button
                  danger
                  onClick={handleBatchRevokeInvites}
                  icon={<DeleteOutlined />}
                  style={{
                    width: isMobileView ? "100%" : "auto",
                  }}
                >
                  Revoke Selected ({selectedInvitations.length})
                </Button>
              )}
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setInviteDrawerVisible(true)}
                style={{
                  padding: "4px 12px",
                  height: 32,
                  width: isMobileView ? "100%" : "auto",
                }}
              >
                Invite Member
              </Button>
            </Flex>
          </Flex>
        </Flex>

        <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
          <Col xs={24} sm={8}>
            <div className={styles.members__stats_card}>
              <Text className={styles.members__stats_title}>Total Enrolled Users</Text>
              <Text className={styles.members__stats_value}>{totalBilledUsers}</Text>
              <Text className={styles.members__stats_detail}>Total number of users that have been enrolled</Text>
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className={styles.members__stats_card}>
              <Text className={styles.members__stats_title}>Active Members</Text>
              <Text className={styles.members__stats_value}>{membersTotal}</Text>
              <Text className={styles.members__stats_detail}>Members currently in your team</Text>
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className={styles.members__stats_card}>
              <Text className={styles.members__stats_title}>Pending Invites</Text>
              <Text className={styles.members__stats_value}>{invitationsTotal}</Text>
              <Text className={styles.members__stats_detail}>Invitations waiting to be accepted</Text>
            </div>
          </Col>
        </Row>

        <Tabs activeKey={activeTab} items={items} onChange={setActiveTab} />

        <Drawer
          title="Invite Team Members"
          open={inviteDrawerVisible}
          onClose={() => {
            setInviteDrawerVisible(false);
            form.resetFields();
          }}
          width={isMobileView ? "100%" : 520}
          placement={isMobileView ? "bottom" : "right"}
          height={isMobileView ? "85%" : "100%"}
          className={styles.members__drawer}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "12px 0",
              }}
            >
              <Button
                type="primary"
                onClick={handleInvite}
                loading={inviting}
                style={{ padding: "4px 12px", height: 32 }}
              >
                Send Invitations
              </Button>
            </div>
          }
        >
          <Form form={form} layout="vertical" style={{ height: "calc(100% - 108px)" }}>
            <Text>
              Invite team members by entering their email addresses below. Press Enter or comma after each email
              address.
            </Text>
            <div style={{ height: 16 }} />
            <Form.Item
              name="emails"
              rules={[
                { required: true, message: "Please enter at least one email address" },
                {
                  validator: (_, value) => {
                    if (!value || value.length === 0) return Promise.resolve();

                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    const invalidEmails = value.filter((email: string) => !emailRegex.test(email));

                    return invalidEmails.length === 0
                      ? Promise.resolve()
                      : Promise.reject("Please enter valid email addresses");
                  },
                },
              ]}
              style={{ flex: 1 }}
            >
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Enter email addresses"
                tokenSeparators={[","]}
                open={false}
                onKeyDown={(e) => {
                  // Prevent space from creating a new tag
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Form>
        </Drawer>
      </Col>
    </Row>
  );
};

export default Members;
