import { LoadingOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Skeleton, Typography, message } from "antd";
import type React from "react";
import { deleteCustomNote } from "../../../service/notes.service";
import styles from "./AITemplateModal.module.scss";
import { type CustomNote, TabType } from "./types";

const List = ({
  setSelectedTab,
  handleEditTemplate,
  customNoteList,
  fetchCustomNoteList,
  isListLoading,
}: {
  setSelectedTab: (tab: TabType) => void;
  setIsPrimaryButtonDisabled?: (disabled: boolean) => void;
  handleEditTemplate: (template: CustomNote) => void;
  isListLoading: boolean;
  customNoteList: CustomNote[];
  fetchCustomNoteList: () => void;
}) => {
  const componentName = "ai-template-modal";

  async function handleDeleteTemplate(pk: number, e?: React.MouseEvent<HTMLElement>) {
    e?.stopPropagation();
    await deleteCustomNote(pk)
      .then(() => {
        message.success("Your template has been deleted");
        fetchCustomNoteList();
      })
      .catch((err) => {
        message.error("Failed to delete template");
        console.log(err);
      });
  }

  function EmptyState() {
    return (
      <div className={styles[`${componentName}__empty-state-container`]}>
        <div className={styles[`${componentName}__empty-state-icon-container`]}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={styles[`${componentName}__empty-state-icon`]}
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.098 19.902a3.75 3.75 0 0 0 5.304 0l6.401-6.402M6.75 21A3.75 3.75 0 0 1 3 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 0 0 3.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008Z"
            />
          </svg>
        </div>

        <div className={styles[`${componentName}__empty-state-text`]}>
          <Typography.Title level={4}>Create your first custom template using Nudge's AI</Typography.Title>
          <Typography.Text>Paste your ideal note and let Nudge's AI create a custom template for you.</Typography.Text>
        </div>

        <div className={styles[`${componentName}__empty-state-button-container`]}>
          <Button type="primary" onClick={() => setSelectedTab(TabType.CREATE)}>
            Create Template
          </Button>
        </div>
      </div>
    );
  }

  if (isListLoading) {
    return (
      <div style={{ paddingInline: "24px", marginTop: "24px", display: "flex", flexDirection: "column", gap: "16px" }}>
        <Skeleton.Button active style={{ width: "550px", height: "92px", borderRadius: "16px" }} />
        <Skeleton.Button active style={{ width: "550px", height: "92px", borderRadius: "16px" }} />
        <Skeleton.Button active style={{ width: "550px", height: "92px", borderRadius: "16px" }} />
      </div>
    );
  }

  if (!customNoteList) {
    return null;
  }

  if (customNoteList.length === 0) {
    return <EmptyState />;
  }

  return (
    <div className={styles[`${componentName}__list-container`]}>
      {customNoteList.map((template) => (
        <div key={template.id} className={styles[`${componentName}__list-item-container`]}>
          <button
            onClick={() => {
              if (template.status !== 1) {
                handleEditTemplate(template);
              }
            }}
            style={{
              cursor: template.status === 1 ? "default" : "pointer",
              // Optional: Disable button if status is 1
              pointerEvents: template.status === 1 ? "none" : "auto",
            }}
          >
            <span className={styles[`${componentName}__list-item`]}>{template.name}</span>
            <span className={styles[`${componentName}__list-item-document`]}>{template.document}</span>
          </button>
          <div className={styles[`${componentName}__list-item-actions-container`]}>
            <ActionContainer
              template={template}
              handleDeleteTemplate={handleDeleteTemplate}
              handleEditTemplate={handleEditTemplate}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default List;

function ActionContainer({
  template,
  handleDeleteTemplate,
  handleEditTemplate,
}: {
  template: CustomNote;
  handleDeleteTemplate: (pk: number, e?: React.MouseEvent<HTMLElement>) => void;
  handleEditTemplate: (template: CustomNote) => void;
}) {
  const componentName = "ai-template-modal";

  function DeleteTemplate() {
    return (
      <Popconfirm
        title="Delete Template"
        description="Are you sure to delete this template?"
        onConfirm={(e?: React.MouseEvent<HTMLElement>) => handleDeleteTemplate(template.pk, e)}
        okText="Yes"
        onCancel={(e) => e?.stopPropagation()}
        cancelText="No"
        style={{
          cursor: "pointer",
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
          />
        </svg>
      </Popconfirm>
    );
  }

  function Actions() {
    return (
      <div className={styles[`${componentName}__list-item-actions`]}>
        <DeleteTemplate />

        {/* Edit Template */}
        <button onClick={() => handleEditTemplate(template)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
            />
          </svg>
        </button>
      </div>
    );
  }

  function LoadingState() {
    return (
      <div className={styles[`${componentName}__list-item-actions`]}>
        <Typography.Text>
          Generating (few mins)
          <LoadingOutlined />
        </Typography.Text>
      </div>
    );
  }

  function FailedState() {
    return (
      <div className={styles[`${componentName}__list-item-actions`]}>
        <Typography.Text style={{ color: "#FF4D4F" }}>Errored</Typography.Text>
        <Actions />
      </div>
    );
  }

  if (template.status === 1) {
    return <LoadingState />;
  }

  if (template.status === 2) {
    return <FailedState />;
  }

  return <Actions />;
}
