import type { UserInfoProps } from "../slices/authSlice";
import { UserTier } from "../slices/authSlice";

export const ONBOARDING_FEATURE_TIMESTAMP = 1738209600 * 1000; // This is the timestamp for the release of the onboarding feature in milliseconds

export const isLastPlusOneNote = (userInfo: UserInfoProps) => {
  return !userInfo.is_paying && userInfo.total_recordings + 1 > userInfo.free_recordings_limit;
};

export const isEligibleForOnboarding = (userInfo: UserInfoProps): boolean => {
  if (!userInfo || !userInfo.created_at) return false;

  // Group users are never eligible
  if (userInfo.tier === UserTier.GROUP || userInfo.group_name) return false;

  // Not eligible if they have already created their first note
  if (userInfo.total_recordings > 0) return false;

  // Compare timestamps directly
  const userCreatedTimestamp = new Date(userInfo.created_at).getTime();
  if (userCreatedTimestamp <= ONBOARDING_FEATURE_TIMESTAMP) {
    return false;
  }

  // User hasn't completed onboarding
  return !userInfo.onboarding_data?.completed || !userInfo.onboarding_data;
};

export const shouldShowTimerBanner = (userInfo: UserInfoProps): boolean => {
  if (!userInfo || !userInfo.created_at) return false;

  // Compare timestamps directly
  const userCreatedTimestamp = new Date(userInfo.created_at).getTime();
  if (userCreatedTimestamp <= ONBOARDING_FEATURE_TIMESTAMP) {
    return false;
  }

  // Show only if onboarding is completed but reward not yet claimed
  return Boolean(userInfo.onboarding_data?.completed) && !userInfo.onboarding_data?.reward_claimed;
};
